import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Loader } from "../../../loading";
import { AddSkubanaShopDocument } from "../../../generated/graphql";

function SkubanaIntegration() {
  const { skubana_cid: cid, skubana_code: code } = useSelector((state: any) => state.root);
  const [called, setCalled] = useState<boolean>(false);
  const [handledError, setHandledError] = useState<boolean>(false);
  const [addShop, { data, loading, error }] = useMutation(AddSkubanaShopDocument, {
    variables: { cid: cid, code: code },
  });

  useEffect(() => {
    if (!called) {
      try {
        addShop();
      } catch (e) {
        console.log(e);
      }
      setCalled(true);
    }
  }, [called, addShop]);

  useEffect(() => {
    if (!handledError && error) {
      console.log(error);
      setHandledError(true);
      setInterval(() => (window.location.href = "/"), 5000);
    }
  }, [error, handledError]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div></div>;
  } else if (data) {
    window.location.href = `/?shop=${data.addSkubanaShop.shop}&otp=${data.addSkubanaShop.otp}&type=SKUBANA`;
    return <p></p>;
  } else {
    return <p></p>;
  }
}

export default SkubanaIntegration;
