import { Card, Grid, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useHistory } from "react-router-dom";
import SearchField from "./SearchField";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { listAllCustomReports } from "../../../../utils/providerServices";
import {
  getCustomReportComparisonTypeString,
  getCustomReportPeriodTypeString,
  getCustomReportTypeString,
} from "./helper";
import { track } from "@amplitude/analytics-browser";
import { USER_EMAIL } from "../../../../utils/const";
import { ordersCard } from "./data";
// import { G4Event } from "../../../../utils/G4Helpers";

export default function Reports() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [orderSearch, setOrderSearch] = useState("");
  const [defaultReports, setDefaultReports] = useState([...ordersCard]);

  useEffect(() => {
    track("[Capabl] Reports page", {
      email: JSON.parse(localStorage.getItem(USER_EMAIL) as any),
    });
  }, []);

  const handleClickReport = (report: any) => {
    dispatch({
      type: "REPORT_BI_QUERY",
      payload: { ...report, reportType: report.reportType, url: history.location.pathname },
    });
    // G4Event("Analytics Report View:Reports", report.reportType);
    history.push("/dashboard/report-view");
    // TODO
    track("[Capabl] Report clicked", {
      email: JSON.parse(localStorage.getItem(USER_EMAIL) as any),
      biQuery: report.biQuery,
      title: report.title,
      type: report.reportType,
    });
  };

  useEffect(() => {
    fetchCustomReports();
  }, []);

  const fetchCustomReports = async () => {
    try {
      const customReport = await listAllCustomReports();
      setDefaultReports((prev) => [
        ...prev,
        ...customReport.map((report: any) => ({
          title: report.name,
          desc: report.description || "",
          icon: AccessTimeIcon,
          biQuery: report.analyticsType,
          label: getCustomReportPeriodTypeString(report.periodType),
          prevLabel: getCustomReportComparisonTypeString(report.comparisonType),
          id: report.id,
          type: getCustomReportTypeString(report.type),
          start: report.start,
          end: report.end,
          reportType: "custom",
        })),
      ]);
    } catch (e) {
      console.log(e, "===> error fetch custom reports");
    }
  };

  const orderReports = useMemo(
    () =>
      defaultReports.filter((_) =>
        _.desc.toLowerCase().includes(orderSearch.toLowerCase()) ||
        _.title.toLowerCase().includes(orderSearch.toLowerCase())
          ? _
          : false
      ),
    [orderSearch, defaultReports]
  );

  return (
    <>
      <Grid item xs={12}>
        <div className="flex flex-row w-full items-end justify-end">
          {/* <Typography variant="subtitle1" className="clamp-2">
            Order Reports
          </Typography> */}
          <SearchField
            placeholder="Search in reports"
            value={orderSearch}
            setValue={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
              setOrderSearch(value)
            }
          />
        </div>
      </Grid>
      {!!orderReports.length && (
        <>
          {orderReports.length > 0 ? (
            orderReports.map((report: any, index: number) => (
              <Grid item xs={3} key={report.title} className="mt-2">
                <Card className="p-4 cursor-pointer" onClick={() => handleClickReport(report)}>
                  <div className="flex flex-row items-start justify-between mb-4">
                    <div className="flex flex-row w-full items-center">
                      {/* <div
                        className=" h-10 flex flex-row items-center justify-center mr-3"
                        style={{ backgroundColor: "#BDBDBD", width: 40 }}
                      >
                        <ShoppingCartIcon style={{ color: "#fff" }} />
                      </div> */}
                      <Typography
                        style={{
                          height: 34,
                          maxWidth: report.reportType === "custom" ? 200 : 268,
                        }}
                        variant="subtitle1"
                        className="clamp-2"
                      >
                        {report.title}
                      </Typography>
                    </div>
                    {report.reportType === "custom" && (
                      <div className="flex flex-row items-start h-full">
                        <div
                          style={{ backgroundColor: "rgba(156, 163, 175, 1)" }}
                          className="rounded-md px-1 bg-gray-400"
                        >
                          <Typography style={{ color: "#fff" }}>custom</Typography>
                        </div>
                      </div>
                    )}
                  </div>
                  <Typography variant="body2" className="mt-3 clamp-2" style={{ height: 40 }}>
                    {report?.desc || ""}
                  </Typography>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <div className="w-full h-64 flex justify-center items-center">
                <p>Not Report Found</p>
              </div>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
