import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";

export enum EnumAlertDialog {
  DIALOG_TEXT,
  DIALOG_PROGRESS,
}

type AlertDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  type: EnumAlertDialog;
};

export default function AlertDialog(props: AlertDialogProps) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      {props.type === EnumAlertDialog.DIALOG_PROGRESS ? (
        <CircularProgress
          style={{ justifyContent: "center", alignSelf: "center", marginBottom: "20px" }}
        />
      ) : (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{props.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>ok</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
