import { Typography } from "@mui/material";
import React from "react";

function Index(props: { children: React.ReactElement; style?: any }) {
  const { children, style } = props;
  return (
    <Typography variant="h5" style={style}>
      {children}
    </Typography>
  );
}

export default Index;
