import { types } from "../types";

const {
  DASHBOARD_VIEW_DATA,
  DASHBOARD_VIEW_DATA_LOADING,
  DASHBOARD_VIEW_DATA_FAILED,
  LAND_PAGE_WEEKLY_ARRAY,
  LAND_PAGE_TOTAL_SPAN,
  LAND_PAGE_SELECTED_FCS,
  DASHBOARD_SPAN_SIX_MONTH,
  DASHBOARD_MONTHLY_ARRAY,
  DASHBOARD_MAP_DATA_FAILED,
  DASHBOARD_MAP_DATA_LOADING,
  DASHBOARD_MAP_DATA,
  DASHBOARD_MONTHS_ARRAY,
} = types;

/** INITIAL STATES FOR ROOT REDUCER */
const initState: DashboardInitStateInterface = {
  trends: [],
  loader: false,
  weekly: [],
  totalSpan: "",
  fcs: [],
  monthly: [],
  totalSpanSix: "",
  mapLoader: false,
  mapData: [],
  months: []
};

export const dashboardReducer = (state = initState, action: ActionInterface) => {
  switch (action.type) {
    /** SUCCESS ACTION WILL EFFECT THROUGH BELOW CASES */
    case DASHBOARD_VIEW_DATA_LOADING:
      return {
        ...state,
        loader: true,
      };
    case DASHBOARD_VIEW_DATA:
      return {
        ...state,
        trends: action.payload,
        loader: false,
      };

    case LAND_PAGE_WEEKLY_ARRAY:
      return {
        ...state,
        weekly: action.payload,
      };

    case DASHBOARD_MAP_DATA_LOADING:
      return {
        ...state,
        mapLoader: true,
      };

    case DASHBOARD_MAP_DATA:
      return {
        ...state,
        mapLoader: false,
        mapData: action.payload,
      };

    case DASHBOARD_MAP_DATA_FAILED:
      return {
        ...state,
        mapLoader: false,
      };

    case LAND_PAGE_TOTAL_SPAN:
      return {
        ...state,
        totalSpan: action.payload,
      };

    case LAND_PAGE_SELECTED_FCS:
      return {
        ...state,
        fcs: action.payload,
      };
    case DASHBOARD_MONTHLY_ARRAY:
      return {
        ...state,
        monthly: action.payload,
      };

    case DASHBOARD_SPAN_SIX_MONTH:
      return {
        ...state,
        totalSpanSix: action.payload,
      };

    case DASHBOARD_MONTHS_ARRAY:
      return {
        ...state,
        months: action.payload,
      };

    /** FAILED ACTION WILL EFFECT THROUGH BELOW CASES */
    case DASHBOARD_VIEW_DATA_FAILED:
      return {
        ...state,
        loader: false,
      };

    /** DEFAULT CASE */
    default:
      return state;
  }
};

interface ActionInterface {
  type: string;
  payload: any;
}

export interface DashboardInitStateInterface {
  trends: [];
  loader: boolean;
  weekly: string[];
  totalSpan: string;
  fcs: string[];
  monthly: string[];
  totalSpanSix: string;
  mapData: any[];
  mapLoader: boolean;
  months: any[];
}
