import { makeStyles } from "@mui/styles";
import { BoxLoading } from "react-loadingg";

export const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BoxLoading color="#2C1D4F" />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    width: "100%",
    height: "100%",
    zIndex: "2000",
  },
});
