import { Logout } from "@mui/icons-material";
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { signOutUser } from "../../../utils/AuthWrapper";
import { USER_EMAIL } from "../../../utils/const";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

function MenuDropdown() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogout() {
    signOutUser().then(() => {
      const email = localStorage.getItem(USER_EMAIL);
      localStorage.removeItem("onboard_" + email);
      localStorage.removeItem("user_email");
      localStorage.removeItem("user_status");
      localStorage.removeItem(USER_EMAIL);
      dispatch({ type: "RESET_ONBOARDING" });
      dispatch({ type: "RESET_ONBOARD" });
      dispatch({ type: "CHANGE_STEP_COUNT", payload: 0 });
      dispatch({ type: "CLEAR_QUERY_PARAMS" });
      dispatch({ type: "LOGOUT_ONBOARDING" });
      dispatch({ type: "LOGOUT_ROOT" });
      window.location.href = "/";
    });
  }

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="medium"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <AccountBoxIcon fontSize="medium" style={{ color: "#BDBDBD" }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleLogout();
            handleClose();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default MenuDropdown;