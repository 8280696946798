import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      light: "#BA68C8",
      main: "#9C27B0",
      dark: "#7B1FA2",
      contrastText: "#fff",
    },
    secondary: {
      light: "#42A5F5",
      main: "#1976D2",
      dark: "#1565C0",
      contrastText: "#fff",
    },
    info: {
      main: "#000",
    },
  },
});

let defaultTheme = createTheme({
  typography: {
    fontFamily: "ITC Avant Garde Gothic Std Medium",
  },
});

theme = responsiveFontSizes(theme);

export { theme, defaultTheme };
