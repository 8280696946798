import { DayOfWeek, DurationType, ShippingWindowType, Shop } from "../generated/graphql";
import { DEFAULT_WORK_DAYS } from "./globalConstants";

export function decimalParser(value: number, toFix?: number) {
  return value
    ? Number.isInteger(value)
      ? value + ""
      : Number(value).toFixed(toFix || 2) + ""
    : "0";
}

export function activePlan(shopList: Shop[], activeShop: string) {
  const activePlan = shopList.find((shop: Shop) => shop.domain === activeShop);

  return (activePlan && activePlan["pricingPlan"]) || "";
}

export function isOldInstallation(shopList: Shop[], activeShop: string) {
  const activeShopObj = shopList.find((shop: Shop) => shop.domain === activeShop);

  return (activeShopObj && activeShopObj["isOldInstallation"]) || false;
}

export const fcItem = {
  warehouseName: "New Fulfillment",
  contractPercent: 99,
  days: DEFAULT_WORK_DAYS,
  delayDuration: 0,
  delayDurationType: DurationType.Minutes,
  delayOrders: false,
  fromTime: new Date(new Date(new Date().setHours(7)).setMinutes(0)),
  toTime: new Date(new Date(new Date().setHours(17)).setMinutes(0)),
  timezone: 0,
  scorecardSchedules: null,
  userId: -1,
  handle: "",
  provider_internal_Id: 0,
  ordersBefore: 0,
  ordersAfter: 0,
  cutoffDate: new Date(new Date(new Date().setHours(17)).setMinutes(0)),
  route: { addresses: [], priority: -1 },
  routingSoftware: "",
  address: "",
  status: "ACTIVE",
  holidays: [],
  orderAccuracyPercent: 99,
  prepDurationTime: 0,
  prepDurationType: DurationType.Days,
  shippingWindowType: ShippingWindowType.Basic,
  shippingPeriod: 0,
  shippingPeriodType: DurationType.Days,
  dayOfShipping: DayOfWeek.Fri,
  salesChannelId: 0,
};
