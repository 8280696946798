import {
  AssignTagRule,
  ExcludeSkuRule,
  FcRights,
  FulfilmentService,
  RouteRule,
  RouteSkuRule,
  SalesChannel,
  SalesChannelRule,
  ScorecardSchedule,
  ScorecardUsers,
  SlaOverwrittingRule,
  SlaOverwrittingSkuRule,
  User,
} from "../../generated/graphql";
import { types } from "../types";

const {
  SETTING_LOADER,
  ALL_USERS_IN_SETTING,
  ALL_SALES_CHANNELS,
  LOADER_FAILED,
  FC_LIST_SETTING,
  RULES_LAODER,
  RULES_LAODER_FAILED,
  ROUTE_RULES_LIST,
  ROUTE_SKU_RULES_LIST,
  ASSIGN_TAG_RULES_LIST,
  SLA_OVERWRITING_SKU_RULES_LIST,
  SALES_CHANNEL_RULES_LIST,
  SLA_OVERWRITING_RULES_LIST,
  EXCLUDE_SKU_RULES_LIST,
  EXCLUDE_RULES_LIST,
  CLEAR_RULES,
  SCHEDULE_SCORECARD_LIST,
  SCORECARD_USERS_LIST,
  FC_RIGHTS_LIST,
  SETTING_CURRENT_STATE,
} = types;

/** INITIAL STATES FOR ROOT REDUCER */
const initState: SettingInitStateInterface = {
  loader: false,
  users: undefined,
  fcList: undefined,
  rulesLoader: false,
  routeRules: undefined,
  routeSKURules: undefined,
  assignTagRules: undefined,
  overwritingSKURules: undefined,
  overwritingRules: undefined,
  skuRules: undefined,
  excludedRules: undefined,
  allScorecardSchedules: undefined,
  scorecardUsers: undefined,
  fcRights: undefined,
  salesChannels: undefined,
  salesChannelRules: undefined,
  settingsCurrentState: 0,
};

export const settingReducer = (state = initState, action: ActionInterface) => {
  switch (action.type) {
    /** SUCCESS ACTION WILL EFFECT THROUGH BELOW CASES */
    case SETTING_LOADER:
      return {
        ...state,
        loader: true,
      };
    case RULES_LAODER:
      return {
        ...state,
        rulesLoader: true,
      };
    case ALL_USERS_IN_SETTING:
      return {
        ...state,
        loader: false,
        users: action.payload,
      };
    case ALL_SALES_CHANNELS:
      return {
        ...state,
        loader: false,
        salesChannels: action.payload,
      };
    case FC_LIST_SETTING:
      return {
        ...state,
        fcList: action.payload,
      };
    case ROUTE_RULES_LIST:
      return {
        ...state,
        rulesLoader: false,
        routeRules: action.payload,
      };
    case ROUTE_SKU_RULES_LIST:
      return {
        ...state,
        rulesLoader: false,
        routeSKURules: action.payload,
      };
    case ASSIGN_TAG_RULES_LIST:
      return {
        ...state,
        rulesLoader: false,
        assignTagRules: action.payload,
      };
    case SLA_OVERWRITING_SKU_RULES_LIST:
      return {
        ...state,
        rulesLoader: false,
        overwritingSKURules: action.payload,
      };
    case SALES_CHANNEL_RULES_LIST:
      return {
        ...state,
        rulesLoader: false,
        salesChannelRules: action.payload,
      };
    case SLA_OVERWRITING_RULES_LIST:
      return {
        ...state,
        rulesLoader: false,
        overwritingRules: action.payload,
      };
    case EXCLUDE_SKU_RULES_LIST:
      return {
        ...state,
        rulesLoader: false,
        skuRules: action.payload,
      };
    case EXCLUDE_RULES_LIST:
      return {
        ...state,
        rulesLoader: false,
        excludedRules: action.payload,
      };
    case SETTING_CURRENT_STATE:
      return {
        ...state,
        settingsCurrentState: action.payload,
      };
    case CLEAR_RULES:
      return {
        ...state,
        users: undefined,
        fcList: undefined,
        rulesLoader: false,
        routeRules: undefined,
        assignTagRules: undefined,
        overwritingSKURules: undefined,
        overwritingRules: undefined,
        skuRules: undefined,
        excludedRules: undefined,
        allScorecardSchedules: undefined,
        scorecardUsers: undefined,
        fcRights: undefined,
      };
    case SCHEDULE_SCORECARD_LIST:
      return {
        ...state,
        allScorecardSchedules: action.payload,
      };
    case SCORECARD_USERS_LIST:
      return {
        ...state,
        scorecardUsers: action.payload,
      };
    case FC_RIGHTS_LIST:
      return {
        ...state,
        fcRights: action.payload,
      };

    /** FAILED ACTION WILL EFFECT THROUGH BELOW CASES */
    case LOADER_FAILED:
      return {
        ...state,
        loader: false,
      };
    case RULES_LAODER_FAILED:
      return {
        ...state,
        rulesLoader: false,
      };

    /** DEFAULT CASE */
    default:
      return state;
  }
};

interface ActionInterface {
  type: string;
  payload: any;
}

export interface SettingInitStateInterface {
  loader: boolean;
  users: User[] | undefined;
  salesChannels: SalesChannel[] | undefined;
  fcList: FulfilmentService[] | undefined;
  rulesLoader: boolean;
  routeRules: RouteRule[] | undefined;
  routeSKURules: RouteSkuRule[] | undefined;
  assignTagRules: AssignTagRule[] | undefined;
  overwritingSKURules: SlaOverwrittingSkuRule[] | undefined;
  overwritingRules: SlaOverwrittingRule[] | undefined;
  salesChannelRules: SalesChannelRule[] | undefined;
  skuRules: ExcludeSkuRule[] | undefined;
  excludedRules: ExcludeSkuRule[] | undefined;
  scorecardUsers: ScorecardUsers[] | undefined;
  allScorecardSchedules: ScorecardSchedule[] | undefined;
  fcRights: FcRights[] | undefined;
  settingsCurrentState: number;
}
