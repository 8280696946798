import { BiTableId } from "../../generated/graphql";
import { types } from "../types";

const { REPORT_BI_QUERY, SET_TAB_COUNT } = types;

/** INITIAL STATES FOR ROOT REDUCER */
export const initState: IReportViewInitStateInterface = {
  selectedReport: {
    title: "",
    desc: "",
    biQuery: BiTableId.ComplaintsCountPerFc,
    icon: "",
    url: "",
  },
  tabCount: 0,
};

export const reportViewReducer = (state = initState, action: ActionInterface) => {
  switch (action.type) {
    /** SUCCESS ACTION WILL EFFECT THROUGH BELOW CASES */
    case REPORT_BI_QUERY:
      return {
        ...state,
        selectedReport: action.payload,
      };

    case SET_TAB_COUNT:
      return {
        ...state,
        tabCount: action.payload,
      };

    /** DEFAULT CASE */
    default:
      return state;
  }
};

interface ActionInterface {
  type: string;
  payload: any;
}

export interface IReportViewInitStateInterface {
  selectedReport: ISelectedReport;
  tabCount: number | string;
}

export interface ISelectedReport {
  title: string;
  desc: string;
  icon: any;
  biQuery: BiTableId;
  url: string;
  type?: string;
  prevLabel?: string;
  label?: string;
  id?: number;
  start?: any;
  end?: any;
  reportType?: string;
}
