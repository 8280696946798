import * as yup from "yup";
import Step1 from "./Main/Step1/Index";
import Step2 from "./Main/Step2/Index";
// import Step10 from "./Main/Step10/Index";
import Step11 from "./Main/Step11/Index";
import Step12 from "./Main/Step12/Index";
import Step13 from "./Main/Step13/Index";
import Step14 from "./Main/Step14/Index";
import AdsClick from "../../Resources/onboarding/ads_click.png";
import Clock from "../../Resources/onboarding/clock.png";
import QuestionMark from "../../Resources/onboarding/question_mark.png";
import { OnboardInintState } from "../../store/reducers/onboard";
import { PricingPlan } from "../../generated/graphql";

export const SignupSteps = {
  0: Step2,
  1: Step1,
};

export const SigninSteps = {
  0: Step14,
  1: Step13,
  // 2: Step10,
  2: Step11,
  3: Step12,
};
 
const sharedSchema = {
  email: yup
    .string()
    .email()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
      "Invalid email address"
    )
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
};

export const signUpValidationSchema = yup.object().shape({
  ...sharedSchema,
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  businessName: yup.string().required("Business Name is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  terms: yup.boolean().oneOf([true], "You must agree to the terms and conditions"),
});

export const loginValidationSchema = yup.object().shape({
  ...sharedSchema,
});

export const signupInitialValues = {
  // 0 step
  firstName: "",
  lastName: "",
  businessName: "",

  // 1 step
  email: "",
  password: "",
  confirmPassword: "",
  terms: false,
};

export const initialValues: Omit<
  OnboardInintState,
  | "step"
  | "email"
  | "password"
  | "confirmPassword"
  | "terms"
  | "firstName"
  | "lastName"
  | "businessName"
  | "stepCount"
  | "discountCode"
> = {
  // 2 step
  providerName: "",

  // 9 step
  provider: "brand",

  // 10 step
  paymentPlan: PricingPlan.Plus,
};

export const loginInitialValues: LoginProps = {
  email: "",
  password: "",
};

export function onboardInitial(
  providerName: string,
  provider: string,
  paymentPlan: PricingPlan
): Onboard {
  return {
    discountCode: "",
    providerName: providerName || "",
    provider: provider || "brand",
    paymentPlan: paymentPlan || PricingPlan.Starter,
  };
}

export const signUpRightContent = [
  {
    title: "Tell us about your business",
    subtitle:
      "Let us know a little bit more about what you do. This information will help us setup your new account.",
    list: [
      {
        icon: AdsClick,
        subtitle: "Improved customer satisfaction",
        caption: "On-time fulfillment is a key factor in customer satisfaction.",
      },
      {
        icon: Clock,
        subtitle: "Increased efficiency",
        caption: "Ensure that processes are running smoothly and efficiently.",
      },
      {
        icon: QuestionMark,
        subtitle: "Delivery accuracy",
        caption: "Actively monitor delivery exceptions before they become problems.",
      },
    ],
  },
  {
    title: "Maximize success with metrics: Track on-time fulfillment with precision!",
    subtitle:
      "In order to provide you with the best possible experience, we need to collect a few pieces of information.",
    list: [
      {
        icon: AdsClick,
        subtitle: "Improved customer satisfaction",
        caption: "On-time fulfillment is a key factor in customer satisfaction.",
      },
      {
        icon: Clock,
        subtitle: "Increased efficiency",
        caption: "Ensure that processes are running smoothly and efficiently.",
      },
      {
        icon: QuestionMark,
        subtitle: "Delivery accuracy",
        caption: "Actively monitor delivery exceptions before they become problems.",
      },
    ],
  },
];

export const loginRightContent = [
  {
    title: "Maximize success with metrics: Track on-time fulfillment with precision!",
    subtitle:
      "In order to provide you with the best possible experience, we need to collect a few pieces of information.",
    list: [
      {
        icon: AdsClick,
        subtitle: "Improved customer satisfaction",
        caption: "On-time fulfillment is a key factor in customer satisfaction.",
      },
      {
        icon: Clock,
        subtitle: "Increased efficiency",
        caption: "Ensure that processes are running smoothly and efficiently.",
      },
      {
        icon: QuestionMark,
        subtitle: "Delivery accuracy",
        caption: "Actively monitor delivery exceptions before they become problems.",
      },
    ],
  },
  {
    title: "Choose a trial plan",
    subtitle:
      "Whether you have 2 or 10 locations, Capabl's pricing is simple, transparent and adapts to the need of your company.",
    list: [
      {
        icon: AdsClick,
        subtitle: "Improved customer satisfaction",
        caption: "On-time fulfillment is a key factor in customer satisfaction.",
      },
      {
        icon: Clock,
        subtitle: "Increased efficiency",
        caption: "Ensure that processes are running smoothly and efficiently.",
      },
      {
        icon: QuestionMark,
        subtitle: "Delivery accuracy",
        caption: "Actively monitor delivery exceptions before they become problems.",
      },
    ],
  },
  {
    title: "Maximize success with metrics: Track on-time fulfillment with precision!",
    subtitle:
      "In order to provide you with the best possible experience, we need to collect a few pieces of information.",
    list: [
      {
        icon: AdsClick,
        subtitle: "Improved customer satisfaction",
        caption: "On-time fulfillment is a key factor in customer satisfaction.",
      },
      {
        icon: Clock,
        subtitle: "Increased efficiency",
        caption: "Ensure that processes are running smoothly and efficiently.",
      },
      {
        icon: QuestionMark,
        subtitle: "Delivery accuracy",
        caption: "Actively monitor delivery exceptions before they become problems.",
      },
    ],
  },
  {
    title: "Maximize success with metrics: Track on-time fulfillment with precision!",
    subtitle:
      "In order to provide you with the best possible experience, we need to collect a few pieces of information.",
    list: [
      {
        icon: AdsClick,
        subtitle: "Improved customer satisfaction",
        caption: "On-time fulfillment is a key factor in customer satisfaction.",
      },
      {
        icon: Clock,
        subtitle: "Increased efficiency",
        caption: "Ensure that processes are running smoothly and efficiently.",
      },
      {
        icon: QuestionMark,
        subtitle: "Delivery accuracy",
        caption: "Actively monitor delivery exceptions before they become problems.",
      },
    ],
  },
  {
    title: "Congrats, ",
    subtitle:
      "You’re on your way to amazing fulfillment insights and automating your operations. Simply confirm the details you’ve shared.",
    list: [],
  },
];

export interface User {
  companyName: string;
  email: string;
  verfied: boolean;
  firstName: string;
  lastName: string;
}

export interface LoginProps {
  email: string;
  password: string;
}

export interface Onboard {
  providerName: string;
  provider: string;
  paymentPlan: PricingPlan;
  discountCode: string;
}
