import { Order, OrderStatus, Tag } from "../../generated/graphql";
import { types } from "../types";
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import moment from "moment";

const {
  SELECTED_ROWS_LIST,
  CHANGE_PAGE_SIZE,
  CHANGE_STATUS,
  SC_NAME,
  SET_ORDER_TABLE_ORDERS,
  SET_ORDERS_COUNT,
  SET_ORDER_ON_EXCLUDE,
  UPDATE_TAGS_ORDERS,
  RESET_ORDER_TABLE,
  SHIPMENT_QUERY_DATE,
  FULFILMENT_CENTER_LIST,
  FULFILMENT_CENTER_ID,
  FULFILMENT_CENTER_SELECTION,
  SALES_CHANNEL_ID,
  SALES_CHANNEL_SELECTION,
  SET_ORDER_TABLE_PAGE,
  SET_ORDERS_WITHOUT_CONCAT,
  SET_CURRENT_CALL,
  SET_CURRENT_PAGE,
  CASE_ORDER_TABLE_LOADER,
  MARKED_SLA_COUNT,
  MARKED_ROUTING_COUNT,
  CLEAR_ADVANCE_FC_LIST,
  SCORECARD_TAB,
} = types;

/** INITIAL STATES FOR ROOT REDUCER */
const now = new Date();
const initState: AdvanceInitStateInterface = {
  selectedRows: [],
  advance: {
    data: {
      getOrders: [],
    },
  },
  pageSize: 100,
  status: OrderStatus.AllOrders,
  count: 0,
  getOrders: [],
  shipmentQueryDate: [
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
    new Date(now.getFullYear(), now.getMonth(), now.getDate()),
  ],
  fcList: [],
  fulfilmentCenterID: "",
  fulfilmentCenterSelection: undefined,
  page: 0,
  currentCall: 1,
  currentPage: 1,
  orderLoader: false,
  markedRouting: undefined,
  markedSlaCount: undefined,
  scorecardTab: 0,
  salesChannelId: null
};

export const advanceReducer = (state = initState, action: ActionInterface) => {
  switch (action.type) {
    /** SUCCESS ACTION WILL EFFECT THROUGH BELOW CASES */
    case CASE_ORDER_TABLE_LOADER:
      return {
        ...state,
        orderLoader: action.payload,
      };
    case SELECTED_ROWS_LIST:
      return {
        ...state,
        selectedRows: action.payload,
      };
    case CHANGE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case CHANGE_STATUS:
      return {
        ...state,
        status: action.payload,
        orderLoader: true,
      };
    case SC_NAME:
      return {
        ...state,
        status: action.payload,
        orderLoader: true,
      };
    case SET_ORDERS_WITHOUT_CONCAT:
      return {
        ...state,
        getOrders: action.payload,
        orderLoader: false,
      };
    case SET_ORDER_TABLE_ORDERS:
      return {
        ...state,
        getOrders: state.getOrders.concat(action.payload),
      };
    case SET_ORDER_ON_EXCLUDE:
      const { array_excluded, isExlude, primaryReason, comment, secondaryReason } = action.payload;
      return {
        ...state,
        getOrders: state.getOrders.map((v: Order) =>
          array_excluded.includes(`${v.orderName}`)
            ? {
                ...v,
                exlude: isExlude,
                exclude_reasons: v.exclude_reasons?.concat({
                  comment: `${comment}`,
                  primary_reason: `${primaryReason}`,
                  secondary_reason: `${secondaryReason}`,
                  first_name: "",
                  last_name: "",
                  happened_at: "",
                  is_excluded: isExlude,
                  __typename: "ExcludeReason",
                }),
              }
            : v
        ),
        orderLoader: false,
      };
    case UPDATE_TAGS_ORDERS:
      const { type, tags } = action.payload;
      return {
        ...state,
        getOrders: state.getOrders.map((order: Order) =>
          state.selectedRows.includes(`${order.orderName}`)
            ? {
                ...order,
                tags:
                  type === "remove"
                    ? order.tags && order.tags.filter((tag: any) => !tags.includes(tag.name))
                    : (order.tags || []).concat(
                        tags.map((tag: Tag) => ({ name: tag, type: "CUSTOM", __typename: "Tag" }))
                      ),
              }
            : order
        ),
      };
    case SHIPMENT_QUERY_DATE:
      return {
        ...state,
        shipmentQueryDate: action.payload,
        orderLoader: true,
        getOrders: [],
      };
    case RESET_ORDER_TABLE:
      return {
        ...state,
        getOrders: [],
        count: 0,
      };
    case SET_ORDERS_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case FULFILMENT_CENTER_LIST:
      return {
        ...state,
        fcList: action.payload,
      };
    case FULFILMENT_CENTER_ID:
      return {
        ...state,
        fulfilmentCenterID: action.payload,
      };
    case FULFILMENT_CENTER_SELECTION:
      return {
        ...state,
        fulfilmentCenterSelection: action.payload,
      };
    case SALES_CHANNEL_ID:
      return {
        ...state,
        salesChannelId: action.payload,
      };
    case SALES_CHANNEL_SELECTION:
      return {
        ...state,
        salesChannelSelection: action.payload,
      };
    case SET_ORDER_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_CURRENT_CALL:
      return {
        ...state,
        currentCall: action.payload,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case MARKED_ROUTING_COUNT:
      return {
        ...state,
        markedRouting: action.payload,
      };
    case MARKED_SLA_COUNT:
      return {
        ...state,
        markedSlaCount: action.payload,
      };
    case CLEAR_ADVANCE_FC_LIST:
      return {
        selectedRows: [],
        advance: {
          data: {
            getOrders: [],
          },
        },
        pageSize: 2000,
        status: OrderStatus.AllOrders,
        count: 0,
        getOrders: [],
        shipmentQueryDate: [
          moment().subtract(1, "days").startOf("day").toDate(),
          moment().endOf("day").toDate(),
        ],
        fcList: [],
        fulfilmentCenterID: "",
        fulfilmentCenterSelection: undefined,
        page: 0,
        currentCall: 1,
        currentPage: 1,
        orderLoader: false,
        markedRouting: undefined,
        markedSlaCount: undefined,
      };
    case SCORECARD_TAB:
      return {
        ...state,
        scorecardTab: action.payload,
      };

    /** DEFAULT CASE */
    default:
      return state;
  }
};

interface ActionInterface {
  type: string;
  payload: any;
}

export interface AdvanceInitStateInterface {
  selectedRows: Array<string>;
  advance: { data: { getOrders: [] } } | undefined;
  pageSize: number;
  salesChannelId: number | null;
  status: OrderStatus;
  count: number;
  getOrders: Order[];
  shipmentQueryDate: RangeInput<Date>;
  fcList: FCInterface[];
  fulfilmentCenterID: string;
  fulfilmentCenterSelection: FCInterface | undefined;
  page: number;
  currentCall: number;
  currentPage: number;
  orderLoader: boolean;
  markedRouting: number | undefined;
  markedSlaCount: number | undefined;
  scorecardTab: number | string;
}

export interface FCInterface {
  title: string;
  fcID: number;
  index: number;
  handle: string;
  contractPercent: number;
}
