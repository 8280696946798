import { createTheme, Theme, responsiveFontSizes } from "@mui/material/styles";

let theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#236194",
      dark: "#003866",
      light: "#5A8EC5",
    },
    secondary: {
      main: "#6161A5",
      dark: "#323776",
      light: "#918ED7",
    },
    background: {
      default: "#f7f7f7",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
    },
  },
  breakpoints: {},
  typography: {
    fontFamily: "Roboto",
    fontSize: 16,
    h1: {
      fontSize: "3rem",
      fontWeight: 500,
      fontFamily: "Rubik",
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 500,
      fontFamily: "Rubik",
    },
    h3: {
      fontSize: "48px",
      fontWeight: 400,
      fontFamily: "Rubik",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
      fontFamily: "Rubik",
    },
    h5: {
      fontSize: 24,
      fontWeight: 500,
      fontFamily: "Rubik",
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      fontFamily: "Rubik",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Rubik",
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: "Rubik",
    },
    button: {
      textTransform: "none",
      fontWeight: 500,
      fontSize: 14,
      fontFamily: "Rubik",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Rubik",
      color: "#1C1B1F",
    },
    subtitle2: {
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Rubik",
      color: "#49454F",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Rubik",
      color: "#49454F",
    },
    overline: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Rubik",
      color: "#000000",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          padding: "8px 16px",
          width: "100%",
        },
      },
      defaultProps: {
        disableElevation: true,
        variant: "contained",
        color: "primary",
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: "20px",
        },
      },
      defaultProps: {
        variant: "outlined",
        size: "small",
        style: {
          fontFamily: "Rubik",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          style: {
            fontFamily: "Roboto",
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        autoComplete: "off",
      },
    },
    MuiAppBar: {
      defaultProps: {},
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  zIndex: {},
  direction: "ltr",
  transitions: {},
  mixins: {},
});

theme = responsiveFontSizes(theme);

export { theme };
