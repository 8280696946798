import { Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  ShopExists,
  attachCardForCustomer,
  finalizeOnboarding,
  initiateOnboarding,
  markShopActive,
  sendOnboardingScheduleMail,
} from "../../../../utils/providerServices";
import { OnboardingInintState } from "../../../../store/reducers/onboarding";
import { ErrorToaster, SuccessToaster } from "../../../../toast";
import { signOutUser } from "../../../../utils/AuthWrapper";
import { USER_EMAIL } from "../../../../utils/const";
import { OnboardingInput, PricingPlan, ShopType } from "../../../../generated/graphql";
import { RootInitStateInterface } from "../../../../store/reducers/root";
import { OnboardInintState } from "../../../../store/reducers/onboard";
import { fcItem } from "../../../../utils/helper";

function Index(props: StepperProps) {
  const { children, formik, step, setOpenDialog } = props;
  const { onboardingUser, shopProviderId, apiKey, password }: OnboardingInintState = useSelector(
    (state: any) => state.onboarding
  );
  const { shopName }: RootInitStateInterface = useSelector((state: any) => state.root);
  const { paymentPlan }: OnboardInintState = useSelector((state: any) => state.onboard);

  const dispatch = useDispatch();

  return (
    <div className={`flex flex-col justify-between`} style={{ minHeight: 590 }}>
      {children}
      <div className="flex flex-row justify-between items-center px-7 py-5">
        <Button
          variant="contained"
          onClick={async () => {
            if (step && step === 1 && formik.values.paymentPlan && onboardingUser) {
              try {
                dispatch({ type: "ONBOARDING_LOADER", payload: true });
                await attachCardForCustomer(
                  onboardingUser?.comp_name ?? "",
                  onboardingUser?.email ?? "",
                  onboardingUser?.firstName ?? "",
                  onboardingUser?.lastName ?? "",
                  formik.values.paymentPlan,
                  { clientSecret: "", coupon: "", customerId: "" }
                );
                dispatch({ type: "ONBOARDING_LOADER", payload: false });
                dispatch({ type: "CHANGE_STEP_COUNT", payload: step + 1 });
              } catch (e) {
                dispatch({ type: "ONBOARDING_LOADER", payload: false });
              }
            }
            if (step && step === 2 && formik.values.providerName === "shopify") {
              console.log("shopify");
              setOpenDialog && setOpenDialog(true);
            } else if (step && step === 2 && formik.values.providerName === "custom") {
              onConnect();
            } else if (
              step &&
              step === 2 &&
              formik.values.providerName !== "shopify" &&
              formik.values.providerName !== "custom"
            ) {
              await sendOnboardingScheduleMail(
                "",
                `${onboardingUser?.email}`,
                `${onboardingUser?.firstName} ${onboardingUser?.lastName}`
              );
              SuccessToaster("Please check your email for onboarding scheduling");
              handleLogout();
            } else {
              dispatch({ type: "CHANGE_STEP_COUNT", payload: step + 1 });
            }
          }}
          startIcon={<ArrowForward />}
          style={{ width: "100%" }}
          color="primary"
        >
          Save & Continue
        </Button>
      </div>
    </div>
  );

  function handleLogout() {
    signOutUser().then(() => {
      const email = localStorage.getItem(USER_EMAIL);
      localStorage.removeItem("onboard_" + email);
      localStorage.removeItem("user_email");
      localStorage.removeItem("user_status");
      localStorage.removeItem(USER_EMAIL);
      dispatch({ type: "RESET_ONBOARDING" });
      dispatch({ type: "RESET_ONBOARD" });
      dispatch({ type: "CHANGE_STEP_COUNT", payload: 0 });
      dispatch({ type: "CLEAR_QUERY_PARAMS" });
      dispatch({ type: "LOGOUT_ONBOARDING" });
      dispatch({ type: "LOGOUT_ROOT" });
      window.location.href = "/";
    });
  }

  async function onConnect() {
    try {
      dispatch({ type: "ONBOARDING_LOADER", payload: true });
      const shopExist = await ShopExists(shopName, ShopType.Custom);
      if (shopExist && shopExist["shopExists"]) {
        ErrorToaster("Shop already exist");
        console.log("shop already exist", shopExist);
        dispatch({ type: "ONBOARDING_LOADER", payload: false });
      } else {
        const response = await initiateOnboarding(
          shopName,
          paymentPlan as PricingPlan,
          undefined,
          undefined,
          ShopType.Custom
        );

        const data: OnboardingInput = {
          fulfillmentCenters: [fcItem],
          invitedUsers: [],
          registeringUser: null,
          shopifyUrl: `${shopName}`,
          shopifyAccessKey: apiKey,
          shopifyPassword: password,
          shopProviderInternalId: shopProviderId,
          planAlias: paymentPlan as PricingPlan,
          shopType: ShopType.Custom,
        };
        let redirectURL = await finalizeOnboarding(data);
        await markShopActive(shopName, ShopType.Custom);
        dispatch({ type: "SET_ONBOARD_SHOPNAME", payload: response.shopifyURL });
        dispatch({ type: "CLEAR_QUERY_PARAMS" });
        dispatch({ type: "SET_SHOP_TYPE", payload: ShopType.Custom });
        dispatch({ type: "CHANGE_STEP_COUNT", payload: (step || 0) + 1 });
        console.log(redirectURL, "redirectURL");
        localStorage.setItem("redirectURL", redirectURL);
        dispatch({ type: "ONBOARDING_LOADER", payload: false });
        setTimeout(() => {
          window.location.href = redirectURL;
        }, 100);
      }
    } catch (error) {
      console.log(`${error}`);
      dispatch({ type: "ONBOARDING_LOADER", payload: false });
    }
  }
}

export default Index;

interface StepperProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  children: React.ReactElement;
  step: number;
  formik: any;
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}
