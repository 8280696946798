import { ApolloClient } from "@apollo/client";
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import { ApolloQueryResult } from "apollo-client";
import moment from "moment";
import {
  AccessToken,
  AddEventsDocument,
  AddEventsMutationVariables,
  AddTagsToOrdersDocument,
  AddTagsToOrdersMutationVariables,
  AssignReasonCodeToOrdersDocument,
  AssignReasonCodeToOrdersMutationVariables,
  AttachCardForCustomerDocument,
  AttachCardForCustomerMutationVariables,
  BiTableId,
  BiViewDocument,
  BiViewQueryVariables,
  BiViewWithTimeSpanDistributionDocument,
  BiViewWithTimeSpanDistributionQueryVariables,
  CancelSubscirptionForActiveShopDocument,
  CancelSubscirptionForActiveShopQueryVariables,
  CreateAssignRuleDocument,
  CreateAssignRuleMutationVariables,
  CreateExcludeRuleDocument,
  CreateExcludeRuleMutationVariables,
  CreateExcludeSkuRuleDocument,
  CreateExcludeSkuRuleMutationVariables,
  CreateRouteRuleDocument,
  CreateRouteRuleMutationVariables,
  CreateSlaOverwrittingRuleDocument,
  CreateSlaOverwrittingRuleMutationVariables,
  CreateSlaOverwrittingSkuRuleDocument,
  CreateSlaOverwrittingSkuRuleMutationVariables,
  Customer,
  DeleteEventDocument,
  DeleteEventMutationVariables,
  DeleteFulfillmentDocument,
  DeleteFulfillmentMutationVariables,
  DeleteReasonCodesDocument,
  DeleteReasonCodesMutationVariables,
  DeleteUserDocument,
  DeleteUserMutationVariables,
  DisableDaylightSavingDocument,
  DisableDaylightSavingMutationVariables,
  DisableFulfillmentDocument,
  DisableFulfillmentMutationVariables,
  DisablePartialFulfimentDocument,
  DisablePartialFulfimentMutationVariables,
  EnableDaylightSavingDocument,
  EnableDaylightSavingMutationVariables,
  EnableFulfillmentDocument,
  EnableFulfillmentMutationVariables,
  EnablePartialFulfimentDocument,
  EnablePartialFulfimentMutationVariables,
  ExcludeOrderDocument,
  ExcludeOrderMutationVariables,
  ExcludeOrReRouteOrdersDocument,
  ExcludeOrReRouteOrdersMutationVariables,
  FetchAllScorecardSchedulesDocument,
  FetchAllScorecardSchedulesQueryVariables,
  FetchAllScorecardUsersDocument,
  FetchAllScorecardUsersQueryVariables,
  FetchAllTagsDocument,
  FetchAllUsersDocument,
  FetchAllUsersQueryVariables,
  FetchAssignTagRulesDocument,
  FetchExcludeRulesDocument,
  FetchExcludeSkuRulesDocument,
  FetchFcRightsDocument,
  FetchFcRoutesDocument,
  FetchFcRoutesQueryVariables,
  FetchInProcessOnboardingDocument,
  FetchInProcessOnboardingQueryVariables,
  FetchInProcessShopDocument,
  FetchInProcessShopQueryVariables,
  FetchPricingPlansDocument,
  FetchPricingPlansQueryVariables,
  FetchRouteRulesDocument,
  FetchSlaOverwrittingForSkuRulesDocument,
  FetchSlaOverwrittingRulesDocument,
  FinalizeOnboardingForCustomerDocument,
  FinalizeOnboardingForCustomerMutationVariables,
  FulfillmentCenterHolidayInput,
  FulfillmentsDocument,
  FulfillmentsQueryVariables,
  FulfilmentRoutesUpdate,
  FulfilmentService,
  GetActiveShopDocument,
  GetActiveShopQueryVariables,
  GetActiveUserRoleDocument,
  GetActiveUserRoleQueryVariables,
  GetAdvanceViewDataDocument,
  GetAdvanceViewDataQueryVariables,
  GetCustomerDetailsDocument,
  GetCustomerDetailsQueryVariables,
  GetEventsDocument,
  GetEventsQueryVariables,
  GetFilteredOrdersCountDocument,
  GetFilteredOrdersCountQueryVariables,
  GetFullfilmentCenterListDocument,
  GetFullfilmentCenterListQueryVariables,
  GetMarkedForReroutingCountDocument,
  GetMarkedForReroutingCountQueryVariables,
  GetMarkedForSlaCountDocument,
  GetMarkedForSlaCountQueryVariables,
  GetMultipleOrdersDocument,
  GetMultipleOrdersQueryVariables,
  GetOrdersDocument,
  GetOrdersQueryVariables,
  GetPaymentIntentDocument,
  GetPaymentIntentQueryVariables,
  GetPricingPlanForActiveShopDocument,
  GetPricingPlanForActiveShopQueryVariables,
  GetReasonCodesDocument,
  GetReasonCodesQueryVariables,
  GetReportsOverviewDocument,
  GetReportsOverviewQueryVariables,
  GetScorecardSummaryDocument,
  GetScorecardSummaryQueryVariables,
  GetScorecardViewDocument,
  GetScorecardViewQueryVariables,
  GetShopsDocument,
  GetShopsQueryVariables,
  GetSingleOrderDocument,
  GetSingleOrderQueryVariables,
  GetStandardViewDocument,
  GetStandardViewQueryVariables,
  InitiateOnboardingDocument,
  InitiateOnboardingMutationVariables,
  InsertFcDocument,
  InsertFcMutationVariables,
  InviteUsersDocument,
  InviteUsersMutationVariables,
  IsEnabledPartialFulfilmentDocument,
  IsEnabledPartialFulfilmentQueryVariables,
  MarkShopActiveDocument,
  MarkShopActiveMutationVariables,
  OnboardingInput,
  Order,
  OrderStatus,
  OverwriteOrdersSlaDocument,
  OverwriteOrdersSlaMutationVariables,
  PricingPlan,
  ReasonCodeInput,
  RemoveAssignRuleDocument,
  RemoveAssignRuleMutationVariables,
  RemoveExcludeRuleDocument,
  RemoveExcludeRuleMutationVariables,
  RemoveExcludeSkuRuleDocument,
  RemoveExcludeSkuRuleMutationVariables,
  RemoveRouteRuleDocument,
  RemoveRouteRuleMutationVariables,
  RemoveSlaOverwrittingRuleDocument,
  RemoveSlaOverwrittingRuleMutationVariables,
  RemoveSlaOverwrittingSkuRuleDocument,
  RemoveSlaOverwrittingSkuRuleMutationVariables,
  RemoveTagsToOrdersDocument,
  RemoveTagsToOrdersMutationVariables,
  ReportsOverview,
  RerouteOrdersDocument,
  RerouteOrdersMutationVariables,
  ScorecardSchedule,
  ScorecardUsers,
  SendHelpEmailDocument,
  SendHelpEmailMutationVariables,
  ShopifyHealthCheckDocument,
  ShopifyHealthCheckQueryVariables,
  ShopType,
  SlaOverwrittingType,
  StripeIntent,
  SummaryScorecard,
  UnassignReasonCodeToOrdersDocument,
  UnassignReasonCodeToOrdersMutationVariables,
  UpdateFcRightsDocument,
  UpdateFcRightsMutationVariables,
  UpdateFulfillmentServiceDocument,
  UpdateFulfillmentServiceMutationVariables,
  UpdatePlanForCustomerDocument,
  UpdatePlanForCustomerMutationVariables,
  UpdateRoutesDocument,
  UpdateRoutesMutationVariables,
  UpdateScorecardScheduleDocument,
  UpdateScorecardScheduleMutationVariables,
  UpdateScorecardUsersDocument,
  UpdateScorecardUsersMutationVariables,
  UpdateUserDocument,
  UpdateUserFirstLastNameDocument,
  UpdateUserFirstLastNameMutationVariables,
  UpdateUserMutationVariables,
  UpsertReasonCodesDocument,
  UpsertReasonCodesMutationVariables,
  User,
  UserInfoDocument,
  UserInfoQueryVariables,
  UserRole,
  VerifyCouponAndReturnDiscountsDocument,
  VerifyCouponAndReturnDiscountsQueryVariables,
  VerifyTokenDocument,
  VerifyTokenMutationVariables,
  WeeklyColumnValuesArg,
  WeeklyColumnValuesForBiDocument,
  WeeklyColumnValuesForBiQueryVariables,
  GetSalesChannelDocument,
  Delay,
  PrimaryExcludeReason,
  GetAllSkUsQueryVariables,
  GetAllSkUsDocument,
  ListAllCustomReportsQueryVariables,
  ListAllCustomReportsDocument,
  DeleteCustomReportsMutationVariables,
  DeleteCustomReportsDocument,
  SaveCustomReportsMutationVariables,
  SaveCustomReportsDocument,
  CustomReportInput,
  ListSalesChannelsQueryVariables,
  ListSalesChannelsDocument,
  SalesChannel,
  UpsertSalesChannelMutationVariables,
  UpsertSalesChannelDocument,
  RemoveSalesChannelMutationVariables,
  RemoveSalesChannelDocument,
  ComparisonType,
  SalesChannelRuleType,
  UpsertSalesChannelRuleMutationVariables,
  UpsertSalesChannelRuleDocument,
  ListSalesChannelRulesDocument,
  RemoveSalesChannelRuleMutationVariables,
  RemoveSalesChannelRuleDocument,
  ShopExistsDocument,
  ShopExistsQueryVariables,
  SubscriptionUrlDocument,
  CreateRouteSkuRuleMutationVariables,
  CreateRouteSkuRuleDocument,
  RemoveRouteSkuRuleDocument,
  RemoveRouteSkuRuleMutationVariables,
  FetchRouteSkuRulesDocument,
  UpdateUserCurrentStepMutationVariables,
  CurrentSteps,
  UpdateUserCurrentStepDocument,
  UpdateSkippedStepMutationVariables,
  UpdateSkippedStepDocument,
} from "../generated/graphql";
import {
  GetShopifyPaymentLinkForActiveShopDocument,
  GetShopifyPaymentLinkForActiveShopQueryVariables,
} from "./../generated/graphql";
import { getCurrentAuthenticatedUser, getJwtToken } from "./AuthWrapper";
import { USER_STATUS } from "./const";
import { getDateRangeFromItem, localTimeZone } from "./dateformatter";

let client: ApolloClient<any> | null = null;
export const initGraphQL = (gqlClient: ApolloClient<any>) => {
  client = gqlClient;
};

export async function fetchStandardViewData(
  fulfilmentCenterID: string | undefined,
  dateRange: RangeInput<Date>,
  onDataRecievedHandler: (result: ApolloQueryResult<any>) => void
) {
  if (!fulfilmentCenterID) {
    return;
  }

  if (dateRange[0] === null && dateRange[1] === null) {
    return;
  }

  let fromDate: Date;
  let toDate: Date;

  if (
    dateRange[0] !== null &&
    dateRange[1] !== null &&
    dateRange[0] !== undefined &&
    dateRange[1] !== undefined &&
    new Date(dateRange[0] as Date).getDate() === new Date(dateRange[1] as Date).getDate() &&
    new Date(dateRange[0] as Date).getMonth() === new Date(dateRange[1] as Date).getMonth() &&
    new Date(dateRange[0] as Date).getFullYear() === new Date(dateRange[1] as Date).getFullYear()
  ) {
    const d = moment(dateRange[0]);
    fromDate = d.utc().startOf("day").toDate();
    toDate = d.utc().endOf("day").toDate();
  } else {
    fromDate = moment(dateRange[0]).utc().startOf("date").toDate();
    toDate = moment(dateRange[1]).utc().endOf("date").toDate();
  }

  let variable: GetStandardViewQueryVariables = {
    reportsOverviewToDate2: toDate,
    reportsOverviewFromDate2: fromDate,
    reportsOverviewFullfilmentCenter2: fulfilmentCenterID,
    sumaryInfoToDate2: toDate,
    sumaryInfoFromDate2: fromDate,
    sumaryInfoFullfilmentCenter2: fulfilmentCenterID,
  };

  let data = await client?.query({
    query: GetStandardViewDocument,
    variables: variable,
  });
  onDataRecievedHandler(data as ApolloQueryResult<any>);
}

export async function fetchScorecardView(
  fulfilmentCenterID: string | undefined,
  advanceViewDate: RangeInput<Date>,
  onDataRecievedHandler: (result: ApolloQueryResult<any>) => void
) {
  let toDateAdv: Date;
  if (!fulfilmentCenterID) {
    return;
  }
  if (advanceViewDate[0] === null && advanceViewDate[1] === null) {
    return;
  }
  toDateAdv = advanceViewDate[1] ? (new Date(advanceViewDate[1]) as Date) : new Date();

  // Hours do not matter but the date does.
  toDateAdv = new Date(
    Date.UTC(toDateAdv.getFullYear(), toDateAdv.getMonth(), toDateAdv.getDate(), 0, 0, 0, 0)
  );
  let variables: GetScorecardViewQueryVariables = {
    FullfilmentCenter: fulfilmentCenterID,
    advanceViewStartingDate2: toDateAdv,
    startingDate: toDateAdv,
  };
  let data = await client?.query({
    query: GetScorecardViewDocument,
    variables: variables,
  });
  onDataRecievedHandler(data as ApolloQueryResult<any>);
}

export async function fetchAdvanceViewData(
  fulfilmentCenterID: string | null,
  dateRange: RangeInput<Date>,
  onDataRecievedHandler: (result: ApolloQueryResult<any>, status?: any, pageNum?: any) => void,
  pageNumber: number,
  filter: string,
  status: OrderStatus,
  pageSize: number,
  salesChannelId: number | null
) {
  if (!fulfilmentCenterID) {
    return;
  }

  // We need to set fulfilmentCenterID to null to fetch data for all FCs
  if (fulfilmentCenterID === "null") fulfilmentCenterID = null;

  let variable: GetAdvanceViewDataQueryVariables = {
    fullfilmentCenter: fulfilmentCenterID,

    // set date time to day start time, sets timezone to 0
    // and then adds number of hours depending on gap in
    // hours between current timezone and utc
    fromDate: moment(dateRange[0])
      .utcOffset(0)
      .add(localTimeZone(), "hours")
      .startOf("day")
      .toString(),

    // set date time to day end time, sets timezone to 0 and
    // then adds number of hours depending on gap in hours
    // between current timezone and utc
    toDate: moment(dateRange[1]).utcOffset(0).add(localTimeZone(), "hours").endOf("day").toString(),
    pageNumber: pageNumber,
    filter: filter,
    status: status,
    pageSize: pageSize,
    salesChannelId: salesChannelId === -1 ? null : salesChannelId
  };

  try {
    let data = await client?.query({
      query: GetAdvanceViewDataDocument,
      variables: variable,
    });

    onDataRecievedHandler(data as ApolloQueryResult<any>, status, pageNumber);
  } catch (error) {
    console.error("Provider services FetchAdvanceview=" + error);
  }
}

export async function fetchFulfilmentCenterList() {
  try {
    let variable: GetFullfilmentCenterListQueryVariables = {};

    let data = await client?.query({
      query: GetFullfilmentCenterListDocument,
      variables: variable,
    });

    return data?.data;
  } catch (error) {
    console.log("fulfilment center list=" + error);
  }
}

export async function excludeOrder(
  array: Array<string>,
  onDataRecievedHandler: (
    isSuccess: boolean,
    array_excluded: Array<string>,
    isExlude: boolean,
    comment?: string,
    primaryReason?: string,
    secondaryReason?: string
  ) => void,
  isExcluded: boolean,
  comment: string = "",
  primaryReason: string = "",
  secondaryReason: string = ""
) {
  let variable: ExcludeOrderMutationVariables = {
    exclude: isExcluded,
    orderIds: array,
    comment: comment,
    primaryReason: primaryReason,
    secondaryReason: secondaryReason,
  };

  try {
    let data = await client?.query({
      query: ExcludeOrderDocument,
      variables: variable,
    });
    if (data?.data.excludeOrder === true) {
      onDataRecievedHandler(true, array, isExcluded, comment, primaryReason, secondaryReason);
    } else {
      onDataRecievedHandler(false, array, isExcluded);
    }
    //
  } catch (error) {
    onDataRecievedHandler(false, array, isExcluded);
  }
}

export async function addEvents(
  array: Array<FulfillmentCenterHolidayInput>,
  fcHandle: string,
  onDataRecievedHandler: (
    isSuccess: boolean,
    array: Array<FulfillmentCenterHolidayInput>,
    errorMessage?: string
  ) => void,
  shopDomain: string
) {
  let variable: AddEventsMutationVariables = {
    holidays: array,
    handle: fcHandle,
    shopName: shopDomain,
  };

  try {
    let data = await client?.query({
      query: AddEventsDocument,
      variables: variable,
    });

    if (data && data.data && data.data.addFulfillmentCentersHolidays === true) {
      onDataRecievedHandler(true, array);
    } else if (data && data.errors && data.errors.length > 0) {
      onDataRecievedHandler(false, array, data.errors[0].message);
    }
  } catch (error) {
    onDataRecievedHandler(false, array, `${error}`);
  }
}

export async function deleteEvent(
  array: Array<number>,
  fcHandle: string,
  onDataRecievedHandler: (isSuccess: boolean, eventIDs: Array<number>) => void,
  shopDomain: string
) {
  let variable: DeleteEventMutationVariables = {
    ids: array,
    handle: fcHandle,
    shopName: shopDomain,
  };

  try {
    let data = await client?.query({
      query: DeleteEventDocument,
      variables: variable,
    });

    if (data?.data.deleteFulfillmentCentersHolidays === true) {
      onDataRecievedHandler(true, array);
    } else {
      onDataRecievedHandler(false, array);
    }
    //
  } catch (error) {
    onDataRecievedHandler(false, array);
  }
}

export async function fetchFCRoutes(
  onDataRecievedHandler: (result: ApolloQueryResult<any>, isSuccess: boolean) => void
) {
  let variable: FetchFcRoutesQueryVariables = {};

  try {
    let data = await client?.query({
      query: FetchFcRoutesDocument,
      variables: variable,
    });
    onDataRecievedHandler(data as ApolloQueryResult<any>, true);
  } catch (error) {
    console.error("Providerservices fecthFCROutesdata=" + error);
  }
}

export async function updateFCRoutes(
  fcServiceId: number,
  address: Array<string>,
  priority: number,
  id: string,
  onDataRecievedHandler: (isSuccess: boolean) => void
) {
  const routes: FulfilmentRoutesUpdate = {
    addresses: address,
    fulfilmentServiceId: fcServiceId,
    priority: priority,
  };
  let variable: UpdateRoutesMutationVariables = {
    routes: routes,
  };

  try {
    await client?.query({
      query: UpdateRoutesDocument,
      variables: variable,
    });
    onDataRecievedHandler(true);
  } catch (error) {
    onDataRecievedHandler(false);
  }
}

/**
 * Create a new Fulfillment Service using warehouse name
 */
export const createFCInDB = (warehouseName: string): Promise<Boolean> => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      let variable: InsertFcMutationVariables = {
        warehouseName: warehouseName,
      };
      let res = await client?.mutate({
        mutation: InsertFcDocument,
        variables: variable,
      });
      if (res && res.errors && res.errors.length > 0) {
        reject(res.errors[0].message);
      }
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Deletes Fulfillment Service by Id
 */
export const deleteFCFromDB = (fcIndex: number): Promise<Boolean> => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      let variable: DeleteFulfillmentMutationVariables = {
        deleteFulfillmentId: fcIndex,
      };
      let res = await client?.mutate({
        mutation: DeleteFulfillmentDocument,
        variables: variable,
      });
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Update FC on basis of updated values
 */
export const updateFCInDB = (fcArry: FulfilmentService[]): Promise<Boolean> => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      let variable: UpdateFulfillmentServiceMutationVariables = {
        fcData: fcArry,
      };
      let res = await client?.mutate({
        mutation: UpdateFulfillmentServiceDocument,
        variables: variable,
      });
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Fetch All Fulfillment Centers
 */
export const fetchFCsFromDB = (): Promise<FulfilmentService[]> => {
  return new Promise<FulfilmentService[]>(async (resolve, reject) => {
    try {
      let variable: FulfillmentsQueryVariables = {};
      let res = await client?.mutate({
        mutation: FulfillmentsDocument,
        variables: variable,
      });
      resolve(res?.data.fetchFulfillments);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Get Scorecard Summary within from Date and to Date
 */
export const getScorecardSummaryFromDB = (
  fromDate: Date,
  toDate: Date
): Promise<SummaryScorecard> => {
  return new Promise<SummaryScorecard>(async (resolve, reject) => {
    try {
      let variable: GetScorecardSummaryQueryVariables = {
        fromDate: fromDate,
        toDate: toDate,
      };
      let res = await client?.mutate({
        mutation: GetScorecardSummaryDocument,
        variables: variable,
      });
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Get Reports Overview within from Date and to Date
 */
export const getReportsOverviewFromDB = (
  fromDate: Date,
  toDate: Date
): Promise<ReportsOverview> => {
  return new Promise<ReportsOverview>(async (resolve, reject) => {
    try {
      let variable: GetReportsOverviewQueryVariables = {
        fromDate: fromDate,
        toDate: toDate,
      };
      let res = await client?.mutate({
        mutation: GetReportsOverviewDocument,
        variables: variable,
      });
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Get Orders within from Date and to Date
 */
export const getOrdersFromDB = (
  fromDate: Date,
  toDate: Date,
  status: OrderStatus
): Promise<Order[]> => {
  return new Promise<Order[]>(async (resolve, reject) => {
    try {
      let variable: GetOrdersQueryVariables = {
        fromDate: fromDate,
        toDate: toDate,
        status: status,
      };
      let res = await client?.mutate({
        mutation: GetOrdersDocument,
        variables: variable,
      });
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Update User using updated values from user type
 */
export const updateUserInDB = (user: User): Promise<Boolean> => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      let variable: UpdateUserMutationVariables = {
        user: {
          id: user.id,
          createdAt: user.createdAt,
          customerId: user.customerId,
          email: user.email,
          role: user.role,
        },
      };
      let res = await client?.mutate({
        variables: variable,
        mutation: UpdateUserDocument,
      });
      if (res && res.errors && res.errors.length > 0) {
        reject(res.errors[0].message);
      }
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Delete User using ID
 */
export const deleteUserInDB = (id: number): Promise<Boolean> => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      let variable: DeleteUserMutationVariables = {
        deleteUserId: id,
      };
      let res = await client?.mutate({
        variables: variable,
        mutation: DeleteUserDocument,
      });
      if (res && res.errors && res.errors.length > 0) {
        reject(res.errors[0].message);
      }
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Fetch User From DB
 */
export const fetchUserByEmailFromDB = (): Promise<User | null> => {
  return new Promise<User | null>(async (resolve, reject) => {
    try {
      let variable: UserInfoQueryVariables = {};
      let res = await client?.mutate({
        variables: variable,
        mutation: UserInfoDocument,
      });
      let user = res?.data.fetchUser;
      if (user === null) {
        localStorage.setItem(USER_STATUS, JSON.stringify("inactive"));
        // reject("User not found!");
      }
      resolve(user);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Fetch All Users From DB
 */
export const fetchAllUsersFromDB = (): Promise<User[]> => {
  return new Promise<User[]>(async (resolve, reject) => {
    try {
      let variable: FetchAllUsersQueryVariables = {};
      let res = await client?.mutate({
        variables: variable,
        mutation: FetchAllUsersDocument,
      });
      resolve(res?.data.fetchAllUsers);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Fetch All Users From DB
 */
export const fetchAllSalesChannelsFromDB = (): Promise<SalesChannel[]> => {
  return new Promise<SalesChannel[]>(async (resolve, reject) => {
    try {
      let variable: ListSalesChannelsQueryVariables = {};
      let res = await client?.mutate({
        variables: variable,
        mutation: ListSalesChannelsDocument,
      });
      resolve(res?.data.listSalesChannels);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Onboard a new Customer using Onboarding Input
 */
export const inviteUsers = (listOfUsers: UserRole[]): Promise<Boolean> => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      let variable: InviteUsersMutationVariables = {
        users: listOfUsers,
      };
      let res = await client?.mutate({
        mutation: InviteUsersDocument,
        variables: variable,
      });
      if (res && res.errors && res.errors.length > 0) {
        reject(res.errors[0].message);
      }
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * upsert sales channel
 */
export const removeSalesChannel = (salesChannel: SalesChannel): Promise<Boolean> => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      let variable: RemoveSalesChannelMutationVariables = {
        salesChannel: salesChannel,
      };
      let res = await client?.mutate({
        mutation: RemoveSalesChannelDocument,
        variables: variable,
      });
      if (res && res.errors && res.errors.length > 0) {
        reject(res.errors[0].message);
      }
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * add new sales channel
 */
export const upsertSalesChannel = (salesChannel: SalesChannel): Promise<Boolean> => {
  return new Promise<Boolean>(async (resolve, reject) => {
    try {
      let variable: UpsertSalesChannelMutationVariables = {
        salesChannel: salesChannel,
      };
      let res = await client?.mutate({
        mutation: UpsertSalesChannelDocument,
        variables: variable,
      });
      if (res && res.errors && res.errors.length > 0) {
        reject(res.errors[0].message);
      }
      resolve(res?.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const getClientSecretFromIntent = (): Promise<StripeIntent | null> => {
  return new Promise<StripeIntent | null>(async (resolve, reject) => {
    try {
      let variable: GetPaymentIntentQueryVariables = {};
      let res = await client?.query({
        query: GetPaymentIntentDocument,
        variables: variable,
      });
      resolve(res?.data.getClientSecretFromPaymentIntent);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const getCustomer = (): Promise<Customer | null> => {
  return new Promise<Customer | null>(async (resolve, reject) => {
    try {
      let variable: GetCustomerDetailsQueryVariables = {};
      let res = await client?.query({
        query: GetCustomerDetailsDocument,
        variables: variable,
      });
      resolve(res?.data.getCustomerDetails);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const getActiveUserRole = (): Promise<UserRole | null> => {
  return new Promise<UserRole | null>(async (resolve, reject) => {
    try {
      let variable: GetActiveUserRoleQueryVariables = {};
      let res = await client?.query({
        query: GetActiveUserRoleDocument,
        variables: variable,
      });
      resolve(res?.data.getActiveUserRole);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const getScorecardSchedules = (): Promise<ScorecardSchedule[] | null> => {
  return new Promise<ScorecardSchedule[] | null>(async (resolve, reject) => {
    try {
      let variable: FetchAllScorecardSchedulesQueryVariables = {};
      let res = await client?.query({
        query: FetchAllScorecardSchedulesDocument,
        variables: variable,
      });
      resolve(res?.data.fetchAllScorecardSchedules);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const updateScorecardSchedules = (schedule: ScorecardSchedule): Promise<number> => {
  return new Promise<number>(async (resolve, reject) => {
    try {
      let variable: UpdateScorecardScheduleMutationVariables = {
        schedule: schedule,
      };
      let res = await client?.mutate({
        mutation: UpdateScorecardScheduleDocument,
        variables: variable,
      });
      if (res && res.errors && res.errors.length > 0) {
        throw new Error(res.errors[0].message);
      }
      resolve(res?.data.updateScorecardSchedule);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const getScorecardUsers = (): Promise<ScorecardUsers[] | null> => {
  return new Promise<ScorecardUsers[] | null>(async (resolve, reject) => {
    try {
      let variable: FetchAllScorecardUsersQueryVariables = {};
      let res = await client?.query({
        query: FetchAllScorecardUsersDocument,
        variables: variable,
      });
      resolve(res?.data.fetchAllScorecardUsers);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const updateScorecardUsers = (emails: string[], scheduleId: number): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: UpdateScorecardUsersMutationVariables = {
        emails: emails,
        scheduleId: scheduleId,
      };
      await client?.mutate({
        mutation: UpdateScorecardUsersDocument,
        variables: variable,
      });
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const shopifyHealthCheck = (): Promise<AccessToken> => {
  return new Promise<AccessToken>(async (resolve, reject) => {
    try {
      let variable: ShopifyHealthCheckQueryVariables = {};
      let res = await client?.query({
        query: ShopifyHealthCheckDocument,
        variables: variable,
      });
      resolve(res?.data.shopifyHealthCheck);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const enableFulfilment = async (fulfilmentId: number) => {
  try {
    let variable: EnableFulfillmentMutationVariables = {
      enableFulfillmentId: fulfilmentId,
    };
    let res = await client?.mutate({
      mutation: EnableFulfillmentDocument,
      variables: variable,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }

    return true;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const disableFulfilment = async (fulfilmentId: number) => {
  try {
    let variable: DisableFulfillmentMutationVariables = {
      disableFulfillmentId: fulfilmentId,
    };
    let res = await client?.mutate({
      mutation: DisableFulfillmentDocument,
      variables: variable,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }

    return true;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const updateFirstLastName = async (firstName: string, lastName: string) => {
  try {
    let variable: UpdateUserFirstLastNameMutationVariables = {
      firstName: firstName,
      lastName: lastName,
    };
    let res = await client?.mutate({
      mutation: UpdateUserFirstLastNameDocument,
      variables: variable,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }

    return true;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const verifyCouponAndReturnDiscount = async (coupon: string) => {
  try {
    let variable: VerifyCouponAndReturnDiscountsQueryVariables = {
      coupon: coupon,
    };
    let res = await client?.query({
      query: VerifyCouponAndReturnDiscountsDocument,
      variables: variable,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }

    return res?.data.verifyCouponAndReturnDiscounts;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchInProcessOnboarding = async () => {
  try {
    let variables: FetchInProcessOnboardingQueryVariables = {};
    let res = await client?.query({
      query: FetchInProcessOnboardingDocument,
      variables: variables,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }
    return res?.data.fetchInProcessOnboarding;
  } catch (e) {
    throw e;
  }
};

export const fetchInProcessShop = async () => {
  try {
    let variables: FetchInProcessShopQueryVariables = {};
    let res = await client?.query({
      query: FetchInProcessShopDocument,
      variables: variables,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }
    return res?.data.fetchInProcessShop;
  } catch (e) {
    throw e;
  }
};

export const attachCardForCustomer = async (
  companyName: string,
  email: string,
  firstName: string,
  lastName: string,
  planName: string,
  stripeIntent: StripeIntent
) => {
  try {
    let variables: AttachCardForCustomerMutationVariables = {
      companyName: companyName,
      firstName: firstName,
      lastName: lastName,
      email: email,
      planName: planName,
      stripeIntent: stripeIntent,
    };
    let res = await client?.mutate({
      mutation: AttachCardForCustomerDocument,
      variables: variables,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }
    return res?.data.attachCardAndCreateCustomer;
  } catch (e) {
    throw e;
  }
};
export const initiateOnboarding = async (
  shopName: string,
  planAlias: PricingPlan,
  apiKey?: string,
  password?: string,
  shopType?: ShopType
) => {
  try {
    let variables: InitiateOnboardingMutationVariables = {
      shopName: shopName,
      plan: planAlias,
      shopApiKey: apiKey ?? "",
      shopPassword: password ?? "",
      shopType: shopType ?? ShopType.Shopify,
    };
    let res = await client?.mutate({
      mutation: InitiateOnboardingDocument,
      variables: variables,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }
    return res?.data.initiatedOnboardingProcess;
  } catch (e) {
    throw e;
  }
};

export const ShopExists = async (shopName: string, shopType: ShopType) => {
  try {
    let variables: ShopExistsQueryVariables = {
      shopName: shopName,
      shopType: shopType,
    };
    let res = await client?.mutate({
      mutation: ShopExistsDocument,
      variables: variables,
    });

    return res?.data;
  } catch (error) {
    console.log(error, "====> ShopExists");
  }
};

export const verifyShopAuth = async (shopName: string, token: string, shopType: ShopType) => {
  try {
    let variables: VerifyTokenMutationVariables = {
      shop: shopName,
      token: token,
      shopType: shopType,
    };
    let res = await client?.mutate({
      mutation: VerifyTokenDocument,
      variables: variables,
    });

    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }
    return res?.data.verifyToken as boolean;
  } catch (e) {
    throw e;
  }
};
export const finalizeOnboarding = async (onboardingValues: OnboardingInput) => {
  try {
    let variable: FinalizeOnboardingForCustomerMutationVariables = {
      onboardingValues: onboardingValues,
    };
    let res = await client?.mutate({
      mutation: FinalizeOnboardingForCustomerDocument,
      variables: variable,
    });

    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.finalizeOnboardingForCustomer;
  } catch (e) {
    console.log(typeof e);
    console.error(e);
    throw e;
  }
};

export const fetchRedirectionURL = async () => {
  try {
    let res = await client?.query({
      query: SubscriptionUrlDocument,
    });

    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.fetchSubscriptionURL;
  } catch (e) {
    console.log(typeof e);
    console.error(e);
    throw e;
  }
};
export const fetchEvents = async (fcHandle: string, shopDomain: string) => {
  try {
    let variable: GetEventsQueryVariables = {
      handle: fcHandle,
      shopName: shopDomain,
    };
    let res = await client?.query({
      query: GetEventsDocument,
      variables: variable,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }

    return res?.data.fetchFulfillmentCenterHolidays;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchPricingPlans = async () => {
  try {
    let variable: FetchPricingPlansQueryVariables = {};
    let res = await client?.query({
      query: FetchPricingPlansDocument,
      variables: variable,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }

    return res?.data.fetchPricingPlans;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const updatePlanForOnboaringCustomer = async (planName: string) => {
  try {
    let variable: UpdatePlanForCustomerMutationVariables = {
      planName: planName,
    };
    let res = await client?.mutate({
      mutation: UpdatePlanForCustomerDocument,
      variables: variable,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }

    return res?.data.updatePlanForCustomer;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const sendHelpEmail = (
  content: string,
  subject: string,
  userEmail: string
): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: SendHelpEmailMutationVariables = {
        content: content,
        subject: subject,
        userEmail: userEmail,
      };
      await client?.mutate({
        mutation: SendHelpEmailDocument,
        variables: variable,
      });
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const GetSingleOrder = async (orderId: string) => {
  try {
    const variable: GetSingleOrderQueryVariables = {
      providerInternalId: orderId,
    };

    let res = await client?.query({
      query: GetSingleOrderDocument,
      variables: variable,
    });

    return res?.data;
  } catch (e) {
    console.error(e);
  }
};

export const GetMultipleOrder = async (orderIds: Array<string>) => {
  try {
    const variable: GetMultipleOrdersQueryVariables = {
      providerInternalIds: orderIds,
    };

    let res = await client?.query({
      query: GetMultipleOrdersDocument,
      variables: variable,
    });

    return res?.data;
  } catch (e) {
    console.error(e);
  }
};

export const GetMarkedForSlaCount = async () => {
  try {
    const variable: GetMarkedForSlaCountQueryVariables = {};

    let res = await client?.query({
      query: GetMarkedForSlaCountDocument,
      variables: variable,
    });

    return res?.data.getMarkedForSLACount;
  } catch (e) {
    console.error(e);
  }
};

export const GetMarkedForReroutingCount = async () => {
  try {
    const variable: GetMarkedForReroutingCountQueryVariables = {};

    let res = await client?.query({
      query: GetMarkedForReroutingCountDocument,
      variables: variable,
    });

    return res?.data.getMarkedForReroutingCount;
  } catch (e) {
    console.error(e);
  }
};

export const addTags = (orderIds: string[], tags: string[]): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: AddTagsToOrdersMutationVariables = {
        provider_internal_ids: orderIds,
        tags: tags,
      };
      await client?.mutate({
        mutation: AddTagsToOrdersDocument,
        variables: variable,
      });
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const removeTags = (orderIds: string[], tags: string[]): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: RemoveTagsToOrdersMutationVariables = {
        provider_internal_ids: orderIds,
        tags: tags,
      };
      await client?.mutate({
        mutation: RemoveTagsToOrdersDocument,
        variables: variable,
      });
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const sendOnboardingScheduleMail = async (
  file: string,
  userEmail: string,
  fullName: string
) => {
  const user = await getCurrentAuthenticatedUser();
  // get the authentication token from local storage if it exists
  let token: string | null = null;
  if (user) {
    token = await getJwtToken();
  }
  const data = new FormData();
  data.append("file", file);
  data.append("email", userEmail);
  data.append("name", fullName);
  const apiAddress = process.env.REACT_APP_API_ADDRESS
    ? process.env.REACT_APP_API_ADDRESS
    : "localhost";
  const httpsString = process.env.REACT_APP_HTTPS ? process.env.REACT_APP_HTTPS : "https";
  const url = `${httpsString}://${apiAddress}/schedule-onboarding`;
  return fetch(url, {
    method: "POST",
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
    body: data,
  });
};

export const fetchFCRights = async () => {
  try {
    const res = await client?.query({
      variables: {},
      query: FetchFcRightsDocument,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }

    return res?.data.fetchFCRights;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const updateFCRights = async (fulfillmentServiceId: number, userIds: number[]) => {
  try {
    const variables: UpdateFcRightsMutationVariables = {
      fulfillmentId: fulfillmentServiceId,
      userIds: userIds,
    };
    const res = await client?.mutate({
      variables: variables,
      mutation: UpdateFcRightsDocument,
    });
    if (res && res.errors && res.errors[0].message) {
      throw Error(res.errors[0].message);
    }

    return true;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export function reorderOrders(fc_handle: string, orderIds: string[]): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: RerouteOrdersMutationVariables = {
        fc_handle: fc_handle,
        orderIds: orderIds,
      };
      await client?.mutate({
        mutation: RerouteOrdersDocument,
        variables: variable,
      });
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export function createRouteRule(fc_handle: string, tags: string[]): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: CreateRouteRuleMutationVariables = {
        fc_handle: fc_handle,
        tags: tags,
      };
      await client?.mutate({
        mutation: CreateRouteRuleDocument,
        variables: variable,
      });
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export function createRouteSKURule(fc_handle: string, skus: string[]): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: CreateRouteSkuRuleMutationVariables = {
        fc_handle: fc_handle,
        skus: skus,
      };
      await client?.mutate({
        mutation: CreateRouteSkuRuleDocument,
        variables: variable,
      });
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export function createAssignRule(literal: string, tags: string[]): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: CreateAssignRuleMutationVariables = {
        literal: literal,
        tags: tags,
      };
      const res = await client?.mutate({
        mutation: CreateAssignRuleDocument,
        variables: variable,
      });
      if (res?.data?.createAssignRule) {
        resolve(true);
      } else {
        reject(false);
      }
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export function createSLAOverwrittingRule(
  date: string,
  delay: number,
  type: SlaOverwrittingType,
  tags: string[]
): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: CreateSlaOverwrittingRuleMutationVariables = {
        date: date,
        delayInDays: delay,
        type: type,
        tags: tags,
      };
      const res = await client?.mutate({
        mutation: CreateSlaOverwrittingRuleDocument,
        variables: variable,
      });
      if (res?.data?.createSLAOverwrittingRule) {
        resolve(true);
      } else {
        reject(false);
      }
      return;
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export function createSLAOverwrittingSkuRule(
  date: string,
  delay: number,
  type: SlaOverwrittingType,
  skus: string[]
): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: CreateSlaOverwrittingSkuRuleMutationVariables = {
        date: date,
        delayInDays: delay,
        type: type,
        skus: skus,
      };
      const res = await client?.mutate({
        mutation: CreateSlaOverwrittingSkuRuleDocument,
        variables: variable,
      });
      if (res?.data?.createSLAOverwrittingSKURule) {
        resolve(true);
      } else {
        reject(false);
      }
      return;
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export async function createSalesChannelRule(
  salesChannelId: number,
  salesChannelRuleType: SalesChannelRuleType,
  literal?: string,
  comparisonType?: ComparisonType,
  count?: number,
  isPrefix: boolean = false
) {
  let variable: UpsertSalesChannelRuleMutationVariables = {
    salesChannelRule: {
      scId: salesChannelId,
      ruleType: salesChannelRuleType,
      literal: literal,
      comparisonType: comparisonType,
      comparisonNumber: count,
      isPrefix: isPrefix,
    },
  };

  await client?.mutate({
    mutation: UpsertSalesChannelRuleDocument,
    variables: variable,
  });

  return true;
}

export function createExcludeRule(exclude: boolean, tags: string[]): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let variable: CreateExcludeRuleMutationVariables = {
        exclude: exclude,
        tags: tags,
      };
      await client?.mutate({
        mutation: CreateExcludeRuleDocument,
        variables: variable,
      });
      resolve(true);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}
export const fetchExcludeRules = async () => {
  try {
    let res = await client?.query({
      query: FetchExcludeRulesDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.fetchExcludeRules;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const fetchExcludeSKURules = async () => {
  try {
    let res = await client?.query({
      query: FetchExcludeSkuRulesDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.fetchExcludeSKURules;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const removeExcludeSkuRule = async (skus: string[]) => {
  try {
    let variables: RemoveExcludeSkuRuleMutationVariables = {
      skus: skus,
    };
    let res = await client?.mutate({
      variables: variables,
      mutation: RemoveExcludeSkuRuleDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.removeExcludeSKURule;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const fetchSLAOverwrittingRules = async () => {
  try {
    let res = await client?.query({
      query: FetchSlaOverwrittingRulesDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.fetchSLAOverwrittingRules;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const removeSlaOverwrittingRule = async (tags: string[]) => {
  try {
    let variables: RemoveSlaOverwrittingRuleMutationVariables = {
      tags: tags,
    };
    let res = await client?.mutate({
      variables: variables,
      mutation: RemoveSlaOverwrittingRuleDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.removeSlaOverwrittingRule;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const fetchSLAOverwrittingForSKURules = async () => {
  try {
    let res = await client?.query({
      query: FetchSlaOverwrittingForSkuRulesDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.fetchSLAOverwrittingForSKURules;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const fetchSalesChannelRulesFromDB = async () => {
  try {
    let res = await client?.query({
      query: ListSalesChannelRulesDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.listSalesChannelRules;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const removeSlaOverwrittingSkuRule = async (skus: string[]) => {
  try {
    let variables: RemoveSlaOverwrittingSkuRuleMutationVariables = {
      skus: skus,
    };
    let res = await client?.mutate({
      variables: variables,
      mutation: RemoveSlaOverwrittingSkuRuleDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.removeSlaOverwrittingSkuRule;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const removeSalesChannelRule = async (
  salesChannelRuleType: SalesChannelRuleType,
  scId: number,
  literal?: string,
  comparisonType?: ComparisonType,
  comparisonNumber?: number,
  isPrefix: boolean = false,
  id?: number
) => {
  try {
    let variables: RemoveSalesChannelRuleMutationVariables = {
      salesChannelRule: {
        ruleType: salesChannelRuleType, 
        scId: scId,
        literal: literal,
        comparisonType: comparisonType,
        comparisonNumber: comparisonNumber,
        isPrefix: isPrefix,
        id: id
      }
    }
    let res = await client?.mutate({
      variables: variables,
      mutation: RemoveSalesChannelRuleDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.removeSalesChannelRule;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const fetchAssignTagRules = async () => {
  try {
    let res = await client?.query({
      query: FetchAssignTagRulesDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.fetchAssignTagRules;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const removeAssignRule = async (tags: string[]) => {
  try {
    let variables: RemoveAssignRuleMutationVariables = {
      tags: tags,
    };
    let res = await client?.mutate({
      variables: variables,
      mutation: RemoveAssignRuleDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.removeAssignRule;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const fetchRouteRules = async () => {
  try {
    let res = await client?.query({
      query: FetchRouteRulesDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.fetchRouteRules;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const fetchRouteSKURules = async () => {
  try {
    let res = await client?.query({
      query: FetchRouteSkuRulesDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.fetchRouteSKURules;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};
export const removeExcludeRules = async (tags: string[]) => {
  try {
    let variables: RemoveExcludeRuleMutationVariables = {
      tags: tags,
    };
    let res = await client?.mutate({
      variables: variables,
      mutation: RemoveExcludeRuleDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return true;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};
export const removeRouteRules = async (tags: string[]) => {
  try {
    let variables: RemoveRouteRuleMutationVariables = {
      tags: tags,
    };
    let res = await client?.mutate({
      variables: variables,
      mutation: RemoveRouteRuleDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return true;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const removeRouteSKURules = async (skus: string[]) => {
  try {
    let variables: RemoveRouteSkuRuleMutationVariables = {
      skus: skus,
    };
    let res = await client?.mutate({
      variables: variables,
      mutation: RemoveRouteSkuRuleDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return true;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const fetchAllTags = async () => {
  try {
    let res = await client?.query({
      query: FetchAllTagsDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.fetchAllTags;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const GetSalesChannel = async () => {
  try {
    let res = await client?.query({
      query: GetSalesChannelDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data.getSalesChannel;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const markShopActive = async (shopDomain: string, shopType: ShopType) => {
  try {
    let variables: MarkShopActiveMutationVariables = {
      shopName: shopDomain,
      shopType,
    };
    let res = await client?.mutate({
      variables: variables,
      mutation: MarkShopActiveDocument,
    });

    return res;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const overwriteOrdersSLA = async (slaDate: string, tags: string[]) => {
  try {
    let variables: OverwriteOrdersSlaMutationVariables = {
      tags: tags,
      slaDate: slaDate,
    };

    let res = await client?.mutate({
      variables: variables,
      mutation: OverwriteOrdersSlaDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return true;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const GetShops = async () => {
  try {
    let variables: GetShopsQueryVariables = {};

    let res = await client?.query({
      variables: variables,
      query: GetShopsDocument,
    });
    return res?.data as { getShops: [{ name: string; domain: string; shopType: ShopType }] };
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const GetActiveShop = async () => {
  try {
    let variables: GetActiveShopQueryVariables = {};

    let res = await client?.query({
      variables: variables,
      query: GetActiveShopDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data as {
      getActiveShop: {
        domain: string;
        type: string;
      };
    };
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const enableDaylightSaving = async (
  offset: number,
  to: any,
  from: any,
  id: number,
  timezone: number | string
) => {
  try {
    let variables: EnableDaylightSavingMutationVariables = {
      offset: offset,
      to: to,
      from: from,
      id: id,
    };

    let res = await client?.query({
      variables: variables,
      query: EnableDaylightSavingDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data;
  } catch (e) {
    console.log(e);
  }
};

export const isEnabledPartialFulfilment = async () => {
  try {
    let variables: IsEnabledPartialFulfilmentQueryVariables = {};

    let res = await client?.query({
      variables: variables,
      query: IsEnabledPartialFulfilmentDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const disableDaylightSaving = async (id: number) => {
  try {
    let variables: DisableDaylightSavingMutationVariables = {
      id: id,
    };

    let res = await client?.query({
      variables: variables,
      query: DisableDaylightSavingDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data;
  } catch (e) {
    console.log(e);
  }
};

export const DisablePartialFulfiment = async () => {
  try {
    let variables: DisablePartialFulfimentMutationVariables = {};

    let res = await client?.query({
      variables: variables,
      query: DisablePartialFulfimentDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const EnablePartialFulfiment = async () => {
  try {
    let variables: EnablePartialFulfimentMutationVariables = {};

    let res = await client?.query({
      variables: variables,
      query: EnablePartialFulfimentDocument,
    });
    if (res && res.errors && res.errors.length > 0) {
      throw new Error(res.errors[0].message);
    }
    return res?.data;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const GeyPaymentLink = async (priceAlias: string) => {
  try {
    let variables: GetShopifyPaymentLinkForActiveShopQueryVariables = {
      paymentPlanAlias: priceAlias,
    };

    let res = await client?.query({
      variables: variables,
      query: GetShopifyPaymentLinkForActiveShopDocument,
    });

    return res?.data.getShopifyPaymentLinkForActiveShop;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const GetPricingPlanForActiveShop = async () => {
  try {
    let variables: GetPricingPlanForActiveShopQueryVariables = {};

    let res = await client?.query({
      variables: variables,
      query: GetPricingPlanForActiveShopDocument,
    });

    return res?.data.getPricingPlanForActiveShop;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const CancelSubscirptionForActiveShop = async () => {
  try {
    let variables: CancelSubscirptionForActiveShopQueryVariables = {};

    await client?.query({
      variables: variables,
      query: CancelSubscirptionForActiveShopDocument,
    });
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const FetchBiView = async (tableIds: BiTableId[]) => {
  try {
    let variables: BiViewQueryVariables = {
      startDate: getDateRangeFromItem(8)[0],
      biQueries: tableIds,
    };

    const res = await client?.query({
      variables: variables,
      query: BiViewDocument,
    });
    return res?.data.biView;
  } catch (e) {
    console.error((e as Error).message);
    throw e;
  }
};

export const CreateExcludeSKURule = async (skus: string[]) => {
  try {
    let variable: CreateExcludeSkuRuleMutationVariables = {
      skus: skus,
    };
    const res = await client?.mutate({
      mutation: CreateExcludeSkuRuleDocument,
      variables: variable,
    });
    return res?.data.createExcludeSkuRule;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const upsertReasonCodes = async (reasonCodes: ReasonCodeInput[]) => {
  try {
    let variable: UpsertReasonCodesMutationVariables = {
      reasonCodes: reasonCodes,
    };
    const res = await client?.mutate({
      mutation: UpsertReasonCodesDocument,
      variables: variable,
    });
    return res?.data.upsertReasonCodes;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const getReasonCodes = async () => {
  try {
    let variable: GetReasonCodesQueryVariables = {};
    const res = await client?.query({
      query: GetReasonCodesDocument,
      variables: variable,
    });
    return res?.data.getReasonCodes;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const deleteReasonCodes = async (reasonCodes: ReasonCodeInput[]) => {
  try {
    let variable: DeleteReasonCodesMutationVariables = {
      reasonCodes: reasonCodes,
    };
    const res = await client?.mutate({
      mutation: DeleteReasonCodesDocument,
      variables: variable,
    });
    return res?.data.deleteReasonCodes;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const excludeOrReRouteOrders = async (obj: {
  routeToFC: string;
  markReRoute: boolean;
  newSLADate: Date | string;
  primaryReason: PrimaryExcludeReason;
  slaStatus: Delay;
  orderStatus: OrderStatus;
  salesChannel: string[] | undefined;
  skuNumber: string[] | undefined;
  tag: string[] | undefined;
  secondaryReason: string;
  toDate: Date | undefined | string;
  fromDate: Date | undefined | string;
  fullfilmentCenter: string;
}) => {
  try {
    let variables = obj;

    if (obj.salesChannel && obj.salesChannel.length === 0) {
      variables["salesChannel"] = undefined;
    }

    if (obj.skuNumber && obj.skuNumber.length === 0) {
      variables["skuNumber"] = undefined;
    }

    if (obj.tag && obj.tag.length === 0) {
      variables["tag"] = undefined;
    }

    if (obj.fromDate) {
      const d = moment(obj.fromDate).utcOffset(0).add(localTimeZone(), "hour");
      variables["fromDate"] = d.startOf("day").toString();
    }

    if (obj.toDate) {
      const d = moment(obj.toDate).utcOffset(0).add(localTimeZone(), "hour");
      variables["toDate"] = d.endOf("day").toString();
    }

    if (obj.newSLADate) {
      const d = moment(obj.newSLADate).utcOffset(0).add(localTimeZone(), "hour");
      variables["newSLADate"] = d.endOf("day").toString();
    }

    let variable: ExcludeOrReRouteOrdersMutationVariables = variables;
    const res = await client?.mutate({
      mutation: ExcludeOrReRouteOrdersDocument,
      variables: variable,
    });
    return res?.data?.excludeOrReRouteOrders;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const assignReasonCode = async (
  reasonCodes: ReasonCodeInput,
  orderIds: string | string[]
) => {
  try {
    let variable: AssignReasonCodeToOrdersMutationVariables = {
      reasonCode: reasonCodes,
      orderIds: orderIds,
    };
    const res = await client?.mutate({
      mutation: AssignReasonCodeToOrdersDocument,
      variables: variable,
    });
    return res?.data.assignReasonCodeToOrders;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const getFilteredOrdersCount = async (obj: {
  orderStatus: OrderStatus;
  salesChannel: string[] | undefined;
  skuNumber: string[] | undefined;
  tag: string[] | undefined;
  slaStatus: Delay;
  toDate: Date | undefined | string;
  fromDate: Date | undefined | string;
  fullfilmentCenter: string;
}) => {
  try {
    let variables = obj;

    if (obj.salesChannel && obj.salesChannel.length === 0) {
      variables["salesChannel"] = undefined;
    }

    if (obj.skuNumber && obj.skuNumber.length === 0) {
      variables["skuNumber"] = undefined;
    }

    if (obj.tag && obj.tag.length === 0) {
      variables["tag"] = undefined;
    }

    if (obj.fromDate) {
      const d = moment(obj.fromDate).utcOffset(0).add(localTimeZone(), "hour");
      variables["fromDate"] = d.startOf("day").toString();
    }

    if (obj.toDate) {
      const d = moment(obj.toDate).utcOffset(0).add(localTimeZone(), "hour");
      variables["toDate"] = d.endOf("day").toString();
    }

    let variable: GetFilteredOrdersCountQueryVariables = variables;
    const res = await client?.mutate({
      mutation: GetFilteredOrdersCountDocument,
      variables: variable,
    });
    return res?.data;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const unassignReasonCode = async (reasonCodes: ReasonCodeInput, orderIds: string[]) => {
  try {
    let variable: UnassignReasonCodeToOrdersMutationVariables = {
      reasonCode: reasonCodes,
      orderIds: orderIds,
    };
    const res = await client?.mutate({
      mutation: UnassignReasonCodeToOrdersDocument,
      variables: variable,
    });
    return res?.data.unassignReasonCodeToOrders;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const weeklyColumnValuesForBI = async (
  endDate: Date,
  startDate?: Date,
  includeDays?: boolean
) => {
  try {
    let variable: WeeklyColumnValuesForBiQueryVariables = {
      endDate: endDate,
      startDate: startDate,
      includeDays: includeDays,
    };
    const res = await client?.mutate({
      mutation: WeeklyColumnValuesForBiDocument,
      variables: variable,
    });
    return res?.data.weeklyColumnValuesForBI;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const biViewWithTimeSpanDistribution = async (
  weeklyColumnValues: WeeklyColumnValuesArg,
  biQueries: BiTableId[]
) => {
  try {
    let variable: BiViewWithTimeSpanDistributionQueryVariables = {
      weeklyColumnValues: weeklyColumnValues,
      biQueries: biQueries,
    };
    const res = await client?.mutate({
      mutation: BiViewWithTimeSpanDistributionDocument,
      variables: variable,
    });
    return res?.data.biViewWithTimeSpanDistribution;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const GetAllSKUs = async () => {
  try {
    let variable: GetAllSkUsQueryVariables = {};
    const res = await client?.mutate({
      mutation: GetAllSkUsDocument,
      variables: variable,
    });
    return res?.data.getAllSKUs;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export async function fetchOrders(
  fulfilmentCenterID: string | null,
  dateRange: RangeInput<Date>,
  pageNumber: number,
  status: OrderStatus
) {
  try {
    if (!fulfilmentCenterID) {
      return;
    }

    // We need to set fulfilmentCenterID to null to fetch data for all FCs
    if (fulfilmentCenterID === "null") fulfilmentCenterID = null;

    let variable: GetAdvanceViewDataQueryVariables = {
      fullfilmentCenter: fulfilmentCenterID,

      // set date time to day start time, sets timezone to 0
      // and then adds number of hours depending on gap in
      // hours between current timezone and utc
      fromDate: moment(dateRange[0])
        .utcOffset(0)
        .add(localTimeZone(), "hours")
        .startOf("day")
        .toString(),

      // set date time to day end time, sets timezone to 0 and
      // then adds number of hours depending on gap in hours
      // between current timezone and utc
      toDate: moment(dateRange[1])
        .utcOffset(0)
        .add(localTimeZone(), "hours")
        .endOf("day")
        .toString(),
      pageNumber: pageNumber,
      filter: null,
      status: status,
      pageSize: 100,
    };
    let data = await client?.query({
      query: GetAdvanceViewDataDocument,
      variables: variable,
    });
    return data?.data;
  } catch (error) {
    console.error("Provider services FetchAdvanceview=" + error);
    return error;
  }
}

export const listAllCustomReports = async () => {
  try {
    let variable: ListAllCustomReportsQueryVariables = {};
    const res = await client?.query({
      query: ListAllCustomReportsDocument,
      variables: variable,
    });
    return res?.data.listAllCustomReports;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const DeleteCustomReports = async (reportId: number) => {
  try {
    let variable: DeleteCustomReportsMutationVariables = {
      reportId: reportId,
    };
    const res = await client?.mutate({
      mutation: DeleteCustomReportsDocument,
      variables: variable,
    });
    return res?.data.deleteCustomReports;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const SaveCustomReports = async (reportInput: CustomReportInput) => {
  try {
    let variable: SaveCustomReportsMutationVariables = {
      report: reportInput,
    };
    const res = await client?.mutate({
      mutation: SaveCustomReportsDocument,
      variables: variable,
    });
    return res?.data.saveCustomReports;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const updateUserCurrentStep = async (currentStep: CurrentSteps) => {
  try {
    let variable: UpdateUserCurrentStepMutationVariables = {
      currentStep: currentStep,
    };
    const res = await client?.mutate({
      mutation: UpdateUserCurrentStepDocument,
      variables: variable,
    });
    return res?.data;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const updateSkippedStep = async (skippedSteps: string) => {
  try {
    let variable: UpdateSkippedStepMutationVariables = {
      skippedSteps: skippedSteps,
    };
    const res = await client?.mutate({
      mutation: UpdateSkippedStepDocument,
      variables: variable,
    });
    return res?.data;
  } catch (e) {
    console.error((e as Error).message);
  }
};

