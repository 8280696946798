import { Card } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { OnboardingInintState } from "../../../store/reducers/onboarding";
import { useStyles } from "../styles";

function Index({ children }: { children: React.ReactElement }) {
  const classes = useStyles();
  const { stepCount }: OnboardingInintState = useSelector((state: any) => state.onboarding);
  const history = useHistory();

  return (
    <Card
      elevation={3}
      style={{ marginTop: history.location.pathname === "/" && stepCount === 0 ? 60 : 0 }}
      className={`${classes.rightCard}`}
    >
      {children}
    </Card>
  );
}

export default Index;
