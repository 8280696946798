import { toast } from "react-toastify";
import { logErrorToLogRocket } from "./utils/logRocket";

/** Singleton toaster instance. Create separate instances for different options. */

export const SuccessToaster = (message: string) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      fontSize: "14px",
    },
  });
};

export const ErrorToaster = (message: string) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      fontSize: "14px",
    },
  });
  logErrorToLogRocket(message, "error");
};

export const WarningToaster = (message: string) => {
  toast.warning(message, {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      fontSize: "14px",
    },
  });
  logErrorToLogRocket(message, "warn");
};
