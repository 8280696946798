import { Box } from "@mui/material";
import React from "react";
import checkIcon from "../../Resources/misc/check-icon.png";

function UserWelcome() {
  return (
    <Box sx={styles.boxRoot}>
      <div style={styles.checkBackgroundTransparent}>
        <div style={styles.checkBackgroundFilled}>
          <img src={checkIcon} style={styles.checkIcon} alt="" />
        </div>
      </div>
      <p style={styles.headTitle}>Welcome to Capabl</p>
      <p style={styles.descText}>
        Your account is set up and you’re ready to go! You'll be redirected to your Dashboard after
        payment and start your journey to increased transparency for your shipments.
      </p>
    </Box>
  );
}

/**
 * @styles defines styles for this component
 */
const styles = {
  boxRoot: {
    backgroundColor: "white",
    width: "560px",
    height: "360px",
    padding: "52px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  checkBackgroundTransparent: {
    width: "50px",
    height: "54px",
    backgroundColor: "rgb(117, 101, 161, 0.3)",
    borderRadius: "50% 50%",
    opacity: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  checkBackgroundFilled: {
    width: "40px",
    height: "40px",
    backgroundColor: "#7565A1",
    borderRadius: "50% 50%",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checkIcon: {
    width: "17px",
    height: "15px",
  } as React.CSSProperties,
  headTitle: {
    fontFamily: "ITC Avant Garde Gothic Std Demi",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    fontStyle: "normal",
    color: "#000000",
    paddingLeft: "15px",
    marginTop: "26px",
  },
  dashboardButton: {
    backgroundColor: "#07A95B",
    color: "white",
    marginTop: "30px",
    width: "270px",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    textTransform: "capitalize",
    height: "47px",
  } as React.CSSProperties,
  descText: {
    color: "#1C4A70",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "10px",
  } as React.CSSProperties,
};
export default UserWelcome;
