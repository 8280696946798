export const handleError = (code: any, msg: string): string => {
  let message = "";
  switch (code) {
    case 400:
      message = errorMsg(
        msg,
        "Something is missing in request, make request again or refresh apps"
      );
      break;
    case 401:
      message = errorMsg(msg, "You are unauthorised to access app");
      break;
    case 0:
      message = errorMsg(msg, "Connection to the server was lost.");
      break;
    case 500:
      message = errorMsg(msg, "Internal server error.");
      break;
    case 504:
      message = errorMsg(msg, "Request timed out.");
      break;
    case 429:
      message = errorMsg(msg, "Too many requests. Try later.");
      break;
    case "BAD_USER_INPUT":
      message = errorMsg(msg, "Something is missing in request, make request again or refresh app");
      break;
    case "UNAUTHENTICATED":
      message = errorMsg(msg, "You are unauthorised to access app");
      break;
    case "FORBIDDEN":
      message = errorMsg(
        msg,
        "The server was unauthorized to access a required data source, such as a REST API."
      );
      break;
    case "INTERNAL_SERVER_ERROR":
      message = errorMsg(msg, "An unspecified error occurred. Refresh app.");
      break;
  }
  return message;
};

function errorMsg(msg: string, customMsg: string) {
  return msg ?? customMsg;
}
