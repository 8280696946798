import { AppBar, Box, Toolbar } from "@mui/material";
import { useSelector } from "react-redux";
import tabbar_logo from "../../../Resources/tabbarIcons/tabbar_logo1.png";
import { OnboardingInintState } from "../../../store/reducers/onboarding";
import { useStyles } from "./styles";
import MenuDropdown from "./Menu";

export default function ButtonAppBar() {
  const classes = useStyles();
  const { onboardingUser }: OnboardingInintState = useSelector((state: any) => state.onboarding);


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flex: 1 }}>
            <img src={tabbar_logo} alt="Capabl Logo" className={classes.logo} />
          </Box>
          {onboardingUser?.email && <MenuDropdown />}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
