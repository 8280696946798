import { types } from "../types";

const { LEGEND_UPDATE } = types;

/** INITIAL STATES FOR ROOT REDUCER */
const initState: InitStateInterface = {
  legends: {},
};

export const legendReducer = (state = initState, action: ActionInterface) => {
  switch (action.type) {
    /** SUCCESS ACTION WILL EFFECT THROUGH BELOW CASES */
    case LEGEND_UPDATE:
      let updated = {};
      if (action?.title) {
        updated = { [action.title]: action.payload };
      }

      return {
        ...state,
        ...updated,
      };

    /** DEFAULT CASE */
    default:
      return state;
  }
};

interface ActionInterface {
  type: string;
  payload: any;
  title?: string;
}

interface InitStateInterface {
  legends: any;
}
