import { ScorecardUsers } from "../../Components/Onboarding/OnboardingContainer";
import { FCRoutingModel } from "../../Components/Onboarding/FCRouting";
import {
  FulfilmentService,
  OnboardingFcHolidays,
  OnboardingUserInput,
  PricingPlans,
  ScorecardSchedule,
  ShopType,
  StripeIntent,
} from "../../generated/graphql";
import { types } from "../types";

const {
  CHANGE_STEP_COUNT,
  SET_SELECTED_FC,
  SET_SELECTED_PLAN,
  SET_PRICING_LIMIT,
  SET_PLAN_STATE,
  SET_SHOP_TYPE,
  SET_ROUTES,
  SET_FULFILMENT_CENTER_LIST,
  SET_ONBOARD_SHOPNAME,
  SET_ONBOAD_APIKEY,
  SET_ONBOARD_PASSWORD,
  SET_PROVIDER_ID,
  SET_SHOPIFY_INTEGRATED,
  LOGOUT_ONBOARDING,
  SET_ONBOADING_USER,
  SET_CARD_ATTACHED,
  ADD_ADMIN_USERS,
  ADD_REGULAR_USERS,
  SET_SCORECARD_SCHEDULE,
  SET_SCORECARD_USERS,
  SET_FC_HOLIDAYS,
  SET_SHOP_STATUS,
  RESET_ONBOARDING,
  CLOSE_FREE_PLAN_POPUP,
  ONBOARDING_LOADER,
  SET_EXISTING_USER_DATA_FROM_LOCALSTORAGE,
} = types;

const initState: OnboardingInintState = {
  stepCount: 0,
  selectedFCs: [],
  selectedPlan: "",
  planLimit: [],
  planState: "",
  shopType: ShopType.Shopify,
  routes: undefined,
  fulfillmentCentersData: undefined,
  shopName: "",
  apiKey: "",
  password: "",
  shopProviderId: -1,
  isShopifyIntegrated: false,
  onboardingUser: undefined,
  cardAttached: false,
  stripeIntent: {
    clientSecret: "",
    customerId: "",
    coupon: "",
  },
  adminUsers: undefined,
  regularUsers: undefined,
  scorecardSchedule: undefined,
  scorecardUsers: undefined,
  fulfillmentHolidays: undefined,
  shopStatus: "",
  freePaymentPlanPopup: false,
  loader: false,
};

export const onboardingReducer = (state = initState, action: ActionInterface) => {
  switch (action.type) {
    case CHANGE_STEP_COUNT:
      return {
        ...state,
        stepCount: action.payload,
      };
    case SET_SELECTED_FC:
      return {
        ...state,
        selectedFCs: action.payload,
      };
    case CLOSE_FREE_PLAN_POPUP:
      return {
        ...state,
        freePaymentPlanPopup: action.payload,
      };
    case SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case SET_PRICING_LIMIT:
      return {
        ...state,
        planLimit: action.payload,
      };
    case SET_PLAN_STATE:
      return {
        ...state,
        planState: action.payload,
      };
    case SET_SHOP_TYPE:
      return {
        ...state,
        shopType: action.payload,
      };
    case SET_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    case SET_FULFILMENT_CENTER_LIST:
      return {
        ...state,
        fulfillmentCentersData: action.payload,
      };
    case SET_ONBOARD_SHOPNAME:
      return {
        ...state,
        shopName: action.payload,
      };
    case SET_ONBOAD_APIKEY:
      return {
        ...state,
        apiKey: action.payload,
      };
    case SET_ONBOARD_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case SET_PROVIDER_ID:
      return {
        ...state,
        shopProviderId: action.payload,
      };
    case SET_SHOPIFY_INTEGRATED:
      return {
        ...state,
        isShopifyIntegrated: action.payload,
      };
    case SET_ONBOADING_USER:
      return {
        ...state,
        onboardingUser: action.payload,
      };
    case SET_CARD_ATTACHED:
      return {
        ...state,
        cardAttached: action.payload,
      };
    case LOGOUT_ONBOARDING:
      return {
        stepCount: 0,
        selectedFCs: [],
        selectedPlan: "",
        planLimit: [],
        planState: "",
        shopType: ShopType.Shopify,
        routes: undefined,
        fulfillmentCentersData: undefined,
        shopName: "",
        apiKey: "",
        password: "",
        shopProviderId: -1,
        isShopifyIntegrated: false,
        onboardingUser: undefined,
        cardAttached: false,
        stripeIntent: {
          clientSecret: "",
          customerId: "",
          coupon: "",
        },
        adminUsers: undefined,
        regularUsers: undefined,
        scorecardSchedule: undefined,
        scorecardUsers: undefined,
        fulfillmentHolidays: undefined,
        shopStatus: "",
      };
    case ADD_ADMIN_USERS:
      return {
        ...state,
        adminUsers: action.payload,
      };
    case ADD_REGULAR_USERS:
      return {
        ...state,
        regularUsers: action.payload,
      };
    case SET_SCORECARD_SCHEDULE:
      return {
        ...state,
        scorecardSchedule: action.payload,
      };
    case SET_SCORECARD_USERS:
      return {
        ...state,
        scorecardUsers: action.payload,
      };
    case SET_FC_HOLIDAYS:
      return {
        ...state,
        fulfillmentHolidays: action.payload,
      };
    case SET_SHOP_STATUS:
      return {
        ...state,
        shopStatus: action.payload,
      };
    case ONBOARDING_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case RESET_ONBOARDING:
      return {
        ...state,
        freePaymentPlanPopup: false,
        stepCount: 0,
        selectedFCs: [],
        selectedPlan: "",
        planLimit: [],
        planState: "",
        shopType: ShopType.Shopify,
        routes: undefined,
        fulfillmentCentersData: undefined,
        shopName: "",
        apiKey: "",
        password: "",
        shopProviderId: -1,
        isShopifyIntegrated: false,
        cardAttached: false,
        stripeIntent: {
          clientSecret: "",
          customerId: "",
          coupon: "",
        },
        adminUsers: undefined,
        regularUsers: undefined,
        scorecardSchedule: undefined,
        scorecardUsers: undefined,
        fulfillmentHolidays: undefined,
      };
    case SET_EXISTING_USER_DATA_FROM_LOCALSTORAGE:
      return {
        ...Object.assign(state, action.payload),
      };
    default:
      return state;
  }
};

interface ActionInterface {
  type: string;
  payload: any;
}

export interface OnboardingInintState {
  stepCount: number;
  selectedFCs: FCRoutingModel[];
  selectedPlan: string;
  planLimit: PricingPlans[];
  planState: string;
  shopType: ShopType;
  routes: FCRoutingModel[] | undefined;
  fulfillmentCentersData: FulfilmentService[] | undefined;
  shopName: string;
  apiKey: string;
  password: string;
  shopProviderId: number;
  isShopifyIntegrated: boolean;
  onboardingUser: OnboardingUserInput | undefined;
  cardAttached: boolean;
  stripeIntent: StripeIntent;
  adminUsers: Array<string> | undefined;
  regularUsers: Array<string> | undefined;
  scorecardSchedule: ScorecardSchedule[] | undefined;
  scorecardUsers: ScorecardUsers[] | undefined;
  fulfillmentHolidays: OnboardingFcHolidays[] | undefined;
  shopStatus: string;
  freePaymentPlanPopup: boolean;
  loader: boolean;
}
