import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { FulfilmentService, OnboardingRoutes } from "../../generated/graphql";
import AlertDialog, { EnumAlertDialog } from "../Dashboard/commonComponets/AlertDialog";
import ChipDialog from "../Dashboard/commonComponets/ChipDialog";
import capablLogo from "../../Resources/onboarding/capabl-icon.png";
import { makeStyles } from "@mui/styles";
import { HelpButton } from "./HelpButton";
import { getRouteTypeByData } from "../../utils/globalConstants";
import { useDispatch, useSelector } from "react-redux";
import { OnboardingInintState } from "../../store/reducers/onboarding";

export interface FCRoutingModel {
  id: string;
  title: string;
  array_states: Array<string>;
  routes: OnboardingRoutes | undefined;
  fc_service: FulfilmentService | undefined;
  fc_service_id?: string;
  priority?: number;
}
export enum RoutesType {
  STATES,
  COUNTRIES,
  ZIP,
}

type FCRoutingProps = {
  array_fcs: Array<FCRoutingModel>;
  onDone: (routes: FCRoutingModel[]) => void;
  isadmin?: boolean;
  selectedFCs: Array<FCRoutingModel>;
  setSelectedFC?: React.Dispatch<React.SetStateAction<any>>;
};

export function FCRouting(props: FCRoutingProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedPlan }: OnboardingInintState = useSelector((state: any) => state.onboarding);
  const [isAlert, setIsAlert] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [routesType, setRoutesType] = React.useState<RoutesType | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (routesType === undefined && props.selectedFCs.length > 0) {
      let addedRoute = null;
      props.selectedFCs.forEach((fc) => {
        if (fc.array_states.length > 0) {
          addedRoute = fc.array_states[0];
        }
      });
      let routeType = getRouteTypeByData(addedRoute ? addedRoute : "");
      setRoutesType(routeType);
    }
  }, [props.selectedFCs, routesType]);

  useEffect(() => {
    if (props.selectedFCs.length === 0) {
      let temp: Array<FCRoutingModel> = [];
      for (let i = 0; i < props.array_fcs.length; i++) {
        temp.push(props.array_fcs[i]);
      }
      if (props.setSelectedFC) {
        props.setSelectedFC(temp);
      } else {
        dispatch({ type: "SET_SELECTED_FC", payload: temp });
      }
    }
  }, [props.selectedFCs]); // eslint-disable-line react-hooks/exhaustive-deps

  let menuRows: Array<FCRoutingModel> = JSON.parse(JSON.stringify(props.array_fcs));

  function onFCChanged(event: any, selectedIndex: string, rowIndex: number) {
    let temp: Array<FCRoutingModel> = props.selectedFCs as Array<FCRoutingModel>;
    const t2: number = temp.findIndex((x) => x.id === selectedIndex);
    const index: number = menuRows.findIndex((x) => x.id === selectedIndex);
    temp[rowIndex] = menuRows[index];
    temp[t2] = getUnselect();
    if (props.setSelectedFC) {
      props.setSelectedFC(temp);
    } else {
      dispatch({ type: "SET_SELECTED_FC", payload: temp });
    }
    setRefresh(!refresh);
  }

  function onTextChange(array_values: Array<any>, rowIndex: number) {
    let addresses: string[] = [];
    array_values.forEach((val) => {
      if (!addresses.includes(val)) {
        addresses.push(val);
      }
    });
    let temp: Array<FCRoutingModel> =
      props.selectedFCs !== undefined ? (props.selectedFCs as Array<FCRoutingModel>) : [];
    if (temp[rowIndex] !== undefined) {
      temp[rowIndex].array_states = addresses;
      if (props.setSelectedFC) {
        props.setSelectedFC(temp);
      } else {
        dispatch({ type: "SET_SELECTED_FC", payload: temp });
      }
    }
  }

  function onRouteTypeChanged(routeType: RoutesType) {
    let temp: Array<FCRoutingModel> =
      props.selectedFCs !== undefined ? (props.selectedFCs as Array<FCRoutingModel>) : [];
    temp.forEach((_, idx) => {
      temp[idx].array_states = [];
    });
    if (props.setSelectedFC) {
      props.setSelectedFC(temp);
    } else {
      dispatch({ type: "SET_SELECTED_FC", payload: temp });
    }
    setRoutesType(routeType);
  }

  function verifyData() {
    if (props.array_fcs.length !== props.selectedFCs?.length) {
      return false;
    }
    let temp: Array<FCRoutingModel> =
      props.selectedFCs !== undefined ? (props.selectedFCs as Array<FCRoutingModel>) : [];
    if (temp.findIndex((x) => x.id === "-1") !== -1) {
      setIsAlert(true);
      return false;
    }

    return true;
  }

  function closeAlert() {
    setIsAlert(false);
  }

  function saveAndContinue() {
    if (verifyData()) {
      let temp: Array<FCRoutingModel> = props.selectedFCs as Array<FCRoutingModel>;
      for (let i = 0; i < temp.length; i++) {
        temp[i].routes = convertToRoutes(temp[i].array_states, i);
        temp[i].priority = i;
      }
      props.onDone(temp);
    }
  }

  function convertToRoutes(array: string[], priority: number) {
    let route: OnboardingRoutes = {
      addresses: array,
      priority: priority,
    };
    return route;
  }

  return (
    <>
      <Box sx={props.isadmin ? styles.boxRootAdmin : styles.boxRoot}>
        <AlertDialog
          isOpen={isAlert}
          onClose={closeAlert}
          title={"Error"}
          message={
            "There are unselected Fulfilment centers in the list. Please assign all Fulfilment centers."
          }
          type={EnumAlertDialog.DIALOG_TEXT}
        />
        <Box sx={styles.boxContainer}>
          <img src={capablLogo} style={styles.capablLogo} alt="" />
          <Box style={styles.headingTitle}>Set up Virtual Warehouses</Box>
          {props.array_fcs.length > 1 && (
            <Box style={styles.headingSubtitle}>
              It looks like you have multiple fulfillment centers. In order to make sure that we
              calculate scorecards correctly we need to designate which of your fulfillment centers
              are primary and secondary.
            </Box>
          )}
          <div
            key={"topBox"}
            style={{
              padding: "20px",
              marginTop: "10px",
              width: "100%",
            }}
          >
            <div className="flex flex-row w-full items-end">
              <Box sx={cellSelectionStyle.rootBox} className="w-3/6">
                <p style={styles.label}>We route our orders based on</p>
                <Select
                  key={"orderRoutesType"}
                  value={routesType ? routesType : RoutesType.STATES}
                  onChange={(event) => {
                    onRouteTypeChanged(event.target.value as RoutesType);
                  }}
                  className={`${classes.inputField} select-field`}
                  style={cellSelectionStyle.selectionBox as React.CSSProperties}
                >
                  <MenuItem value={RoutesType.COUNTRIES}>Countries</MenuItem>
                  <MenuItem value={RoutesType.STATES}>States</MenuItem>
                  <MenuItem value={RoutesType.ZIP}>Zip Codes</MenuItem>
                </Select>
              </Box>
              <p
                className={`w-3/6 text-xs pl-4 text-justify ${classes.hint}`}
                style={{ paddingBottom: 14 }}
              >
                Based on what criteria you route your orders
              </p>
            </div>
          </div>

          <Box sx={styles.boxTable}>
            {(selectedPlan === "LITE" ? props.selectedFCs.slice(0, 1) : props.selectedFCs).map(
              (x: FCRoutingModel, index) => {
                let fc: FCRoutingModel | undefined = undefined;
                if (props.selectedFCs !== undefined) {
                  fc = (props.selectedFCs as Array<FCRoutingModel>)[index];
                }
                let selectedValue: string = fc === undefined ? "-1" : fc.id;
                return (
                  <div
                    key={index}
                    style={{
                      padding: "20px",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <div className="flex flex-row w-full items-end">
                      <Box sx={cellSelectionStyle.rootBox} className="w-3/6">
                        <p style={styles.label}>
                          {index > 0 ? "Secondary Warehouse: " : "Primary Warehouse"}
                        </p>
                        <Select
                          key={index}
                          value={selectedValue}
                          onChange={(event) => {
                            onFCChanged(event, event.target.value as string, index);
                          }}
                          disabled={selectedPlan === "LITE"}
                          className={`${classes.inputField} select-field`}
                          style={cellSelectionStyle.selectionBox as React.CSSProperties}
                        >
                          {menuRows.map((item: FCRoutingModel, index: number) => (
                            <MenuItem key={index} value={item.id}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <p
                        className={`w-3/6 text-xs pl-4 text-justify ${classes.hint}`}
                        style={{ paddingBottom: index > 0 ? 6 : 0 }}
                      >
                        {index > 0
                          ? " Capabl expects orders in only these areas to be fulfilled by this fulfillment center"
                          : "Capabl expects all orders to be fulfilled by this fulfillment center unless clearly handled by another fulfillment center"}
                      </p>
                    </div>
                    <Box sx={cellTextStyles.selectionBox}>
                      <ChipDialog
                        array_defaults={fc?.array_states !== undefined ? fc.array_states : []}
                        onChange={function (array_zips: any[]): void {
                          onTextChange(array_zips, index);
                        }}
                        routesType={routesType ? routesType : RoutesType.STATES}
                      />
                    </Box>
                  </div>
                );
              }
            )}
          </Box>
          <Box
            style={{
              padding: "20px",
              marginTop: "10px",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={styles.inputLabel}>Which software routes your orders? (Optional)</p>
            </div>
            <TextField type="text" className={classes.inputField1} />
          </Box>
          <p style={{ ...styles.headingSubtitle, marginBottom: "5px", padding: "0px 20px" }}>
            Capabl is not an OMS, but is able to mimic the order routing guides you have in an OMS
            by desiginating primary and secondary warehouses. These designations will impact how
            many orders are expected to be fulfilled at each of your fulfillment centers and their
            respective SLAs.
          </p>
          <p style={{ ...styles.headingSubtitle, marginBottom: "0px", padding: "0px 20px" }}>
            You can address any real world deviations from these rules on a order by order basis
            inside the app to give you a more precise scorecard.
          </p>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Button
              variant="contained"
              style={styles.saveAndContinue}
              size="large"
              onClick={() => saveAndContinue()}
            >
              Save & Continue
            </Button>
          </Box>
        </Box>

        <div className="mt-5 flex flex-row justify-end">
          <p className=" cursor-pointer underline text-gray-500" onClick={() => setOpen(true)}>
            Help
          </p>
        </div>
        <HelpButton open={open} setOpen={setOpen} subject="Setup Rules For Routing Orders" />
      </Box>
    </>
  );
}

function getUnselect() {
  let unselected: FCRoutingModel = {
    id: "-1",
    title: "",
    array_states: [],
    routes: undefined,
    fc_service: undefined,
  };
  return unselected;
}

const cellSelectionStyle = {
  rootBox: {},
  selectionBox: {
    width: "100%",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    marginTop: "10px",
    backgroundColor: "white",
    position: "relative",
  },
};

const cellTextStyles = {
  boxRoot: {},
  selectionBox: {
    width: "100%",
    marginLeft: "0px",
    marginTop: "10px",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    border: "1px solid lightgrey",
    backgroundColor: "white",
    borderRadius: "4px",
    position: "relative",
    padding: "0px",
  },
};

const styles = {
  rootDiv: {},
  capablLogo: {
    width: "165px",
    height: "35px",
  },
  headingTitle: {
    fontFamily: "ITC Avant Garde Gothic Std Demi",
    fontWeight: 600,
    fontSize: "21px",
    fontStyle: "normal",
    color: "#1C4A70",
    marginTop: "20px",
    marginBottom: "20px",
  },
  label: {
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    fontSize: "14px",
    fontStyle: "normal",
    color: "#1C4A70",
  },
  headingSubtitle: {
    fontFamily: "ITC Avant Garde Gothic Std Book",
    fontWeight: 500,
    fontSize: "16px",
    fontStyle: "normal",
    color: "#1C4A70",
    marginBottom: "20px",
    textAlign: "justify",
    padding: "20px",
  } as React.CSSProperties,
  inputLabel: {
    color: "#435470",
    textAlign: "left",
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    fontSize: "14px",
  } as React.CSSProperties,
  boxRoot: {
    background: "#ffffff",
    width: "100%",
    minHeight: "300px",
    display: "flex",
    flexDirection: "column",
    padding: "30px",
  },
  boxRootAdmin: {
    background: "#ffffff",
    width: "920px",
    minHeight: "300px",
    display: "flex",
    flexDirection: "column",
    padding: "30px",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
  },
  boxTitle: {
    marginTop: "10px",
    justifyContent: "center",
    color: "#435470",
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  saveAndContinue: {
    alignSelf: "center",
    backgroundColor: "#07A95B",
    color: "white",
    width: "230px",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    textTransform: "capitalize",
    height: "40px",
  } as React.CSSProperties,
  boxTable: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  } as React.CSSProperties,
  boxRow: {} as React.CSSProperties,
  desc: {
    fontSize: "16px",
    color: "#1C4A70",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    width: "100%",
    textAlign: "justify",
    marginTop: "10px",
  } as React.CSSProperties,
};

const useStyles = makeStyles({
  inputField: {
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
  },
  inputField1: {
    width: "100%",
    marginTop: "10px",
    "& .MuiOutlinedInput-input": {
      padding: "13.4px",
      fontFamily: "ITC Avant Garde Gothic Std Book",
      fontSize: "14px",
    },
  },
  hint: {
    color: "#1C4A70",
    fontFamily: "ITC Avant Garde Gothic Std Book",
  },
});
