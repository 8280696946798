import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  InputAdornment,
  TextField,
  Typography,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useDispatch, useSelector } from "react-redux";
import { RootInitStateInterface } from "../../../../store/reducers/root";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShopifyDialog(props: Props) {
  const { open, setOpen, handleConnect } = props;
  const dispatch = useDispatch();
  const { shopName }: RootInitStateInterface = useSelector((state: any) => state.root);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Connect to your store</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="Store ID"
          variant="filled"
          size="small"
          value={shopNameOnly(shopName)}
          onChange={(event: any) =>
            dispatch({
              type: "QUERY_PARAM_SHOP_NAME",
              payload: event.target.value,
            })
          }
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Typography>.myshopify.com</Typography>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions sx={{ paddingBottom: "20px" }}>
        <Button sx={{ width: "100px" }} variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          sx={{ width: "100px" }}
          variant="contained"
          disabled={!shopName}
          onClick={handleConnect}
        >
          Connect
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function shopNameOnly(shopName: string) {
  if (shopName.includes(".myshopify.com")) {
    return shopName.split(".")[0];
  }

  return shopName;
}

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleConnect: () => void;
  formik: any;
}
