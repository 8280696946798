import { makeStyles } from "@mui/styles";
import React from "react";

function SendIcon() {
  const classes = useStyles();

  return (
    <>
      <svg
        className={`${classes.svg} ${classes.root}`}
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="SendIcon"
        style={{
          transform: "rotate(-45deg)",
          color: "rgb(255, 255, 255)",
          marginLeft: "5px",
          marginTop: "-5px",
          fontSize: "40px",
        }}
      >
        <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z"></path>
      </svg>
    </>
  );
}

export default SendIcon;

export const useStyles = makeStyles({
  svg: {
    verticalAlign: "middle",
  },
  root: {
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    flexShrink: 0,
    userSelect: "none",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fill: "currentColor",
  },
});
