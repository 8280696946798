import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { RootInitStateInterface } from "./store/reducers/root";
import { USER_EMAIL, USER_STATUS } from "./utils/const";

interface AuthBeforeProps {
  path: string;
  children: any;
}

/**
 * @description this component is authentitated route for the user before login
 * @param {AuthBeforeProps} 
 * @returns {JSX}
 */

function AuthBefore({ children, path }: AuthBeforeProps) {
  const { shopName, token }: RootInitStateInterface = useSelector((state: any) => state.root);
  const email = localStorage.getItem(USER_EMAIL);
  let user_status = "";
  if (localStorage.getItem(USER_STATUS)) {
    user_status = JSON.parse(localStorage.getItem(USER_STATUS) ?? "");
  }

  return (
    <Route path={path}>
      {!email ? (
        children
      ) : user_status === "active" ? (
        shopName && token ? (
          <Redirect to="/dashboard/create-shop" />
        ) : (
          <Redirect to="/dashboard" />
        )
      ) : (
        children // <Redirect to="/" />
      )}
    </Route>
  );
}

export default AuthBefore;
