import { ThemeProvider } from "@mui/material";
import Reports from "./Reports";
import { reportsTheme } from "./theme";

/**
 * @description This is the wrapper for the Reports page, use the custom theme for the repots page only
 * @returns {JSX}
 */

export default function Index() {
  return (
    <ThemeProvider theme={reportsTheme}>
      <Reports />
    </ThemeProvider>
  );
}
