import {
  Button,
  createTheme,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { verifyEmail, verifyPassword } from "../../utils/validations";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  forgotPassword,
  resetPasswordFromLink,
  resetPasswordWithCode,
} from "../../utils/AuthWrapper";
import { updateFirstLastName } from "../../utils/providerServices";
import { ErrorToaster, WarningToaster } from "../../toast";
import { RootInitStateInterface } from "../../store/reducers/root";
import { useSelector } from "react-redux";

export interface ForgotPassProps {
  showVerif: boolean;
  generatedPass: string;
  verificationCode: string;
  user: string;
  onPasswordForgotDone: () => void;
  handleResetSuccess: () => void;
  onModalHide: () => void;
  handleForgotPassword: () => void;
}
export default function ForgotPassword(props: ForgotPassProps) {
  const classes = useStyles();
  const [email, setEmail] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");
  const { showPassword }: RootInitStateInterface = useSelector((state: any) => state.root);

  async function forgetPassword() {
    if (props.showVerif) {
      if (verifyPass()) {
        console.log("verifyPass code =====> " + props.verificationCode);
        try {
          await resetPasswordFromLink(
            props.user,
            password1,
            props.generatedPass,
            firstName,
            lastName
          );
          await updateFirstLastName(firstName, lastName);
          props.onPasswordForgotDone();
        } catch (error) {
          setError("Unable to reset password, please check code and passwords");
        }
      }
    } else if (props.verificationCode !== "") {
      console.log("Verification code =====> " + props.verificationCode);
      if (verifyEmail(email) && verifyPass()) {
        console.log("Verification code & verifyPass =====> " + props.verificationCode);
        try {
          await resetPasswordWithCode(email, password1, props.verificationCode);
          props.handleResetSuccess();
        } catch (error) {
          if (error && (error as any).message) {
            ErrorToaster((error as any).message);
          } else {
            ErrorToaster("Error occurred while setting new password.");
          }
        }
      }
    } else {
      if (verifyEmail(email)) {
        await forgotPassword(email);
        props.handleForgotPassword();
      }
    }
  }

  function verifyPass() {
    if (password1 === password2) {
      return true;
    }
    WarningToaster("Password does not matches with Re-Enter passwords.");
    return false;
  }

  const passwordField = (
    pass: string,
    setPass: Dispatch<SetStateAction<string>>,
    label: string
  ) => {
    return (
      <TextField
        required
        label={label}
        error={!verifyPassword(pass)}
        className={classes.inputField}
        style={styles.shopifyInput}
        variant="filled"
        size="medium"
        id="outlined-adornment-password"
        type={isShowPassword ? "text" : "password"}
        value={pass}
        onChange={(event: any) => {
          setPass(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setIsShowPassword(!isShowPassword);
                }}
                edge="end"
              >
                {isShowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <Modal open={showPassword} disableAutoFocus={true} onClose={props.onModalHide}>
      <div className={classes.modalContainer}>
        <div style={styles.tabPanelTitleContainer}>
          <Typography variant="h5">
            {props.showVerif || props.verificationCode !== ""
              ? "Set a new password"
              : "Forget Password"}
          </Typography>
          <span
            onClick={() => {
              props.onModalHide();
              setEmail("");
              setPassword1("");
              setPassword2("");
            }}
            style={styles.crossIcon}
          >
            <Close />
          </span>
        </div>
        <hr />

        <div style={{ padding: "20px", paddingTop: "0px", paddingBottom: "30px" }}>
          {props.showVerif || props.verificationCode !== "" ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginTop: 20 }}>
                <div style={styles.tagsInputContainer}>
                  <TextField
                    label="First Name"
                    variant="filled"
                    size="medium"
                    className={classes.inputField}
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    style={styles.shopifyInput}
                  />
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <div style={styles.tagsInputContainer}>
                  <TextField
                    label="Last Name"
                    variant="filled"
                    size="medium"
                    className={classes.inputField}
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    style={styles.shopifyInput}
                  />
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <div style={styles.tagsInputContainer}>
                  {passwordField(password1, setPassword1, "Enter new Password")}
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <div style={styles.tagsInputContainer}>
                  {passwordField(password2, setPassword2, "Re-Enter new Password")}
                </div>
              </div>
            </div>
          ) : null}

          {!props.showVerif ? (
            <div style={{ marginTop: 10, marginBottom: 20 }}>
              <TextField
                label="Enter your email"
                variant="filled"
                size="medium"
                fullWidth
                className={classes.inputField}
                error={!verifyEmail(email)}
                helperText={!verifyEmail(email) ? "Please provide a valid email" : ""}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          ) : null}

          <div style={{ textAlign: "center" }}>
            <Button onClick={() => forgetPassword()} style={{ width: "40%" }}>
              {props.showVerif || props.verificationCode !== ""
                ? "Set password"
                : "Forgot Password"}
            </Button>
          </div>

          {error && (
            <div>
              <span style={styles.error}>{error}</span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  tabPanelTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    paddingBottom: "10px",
  },
  tabPanelTitle: {
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    fontSize: "18px",
    color: "#236194",
    width: "100%",
  } as React.CSSProperties,
  labelContainer: {} as React.CSSProperties,
  label: {
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    fontSize: "14px",
    color: "#435470",
  } as React.CSSProperties,
  tagsInputContainer: {
    width: "100%",
  } as React.CSSProperties,
  inviteButton: {
    width: "230px",
    height: "40px",
    backgroundColor: "#07A95B",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    color: "white",
    marginTop: "30px",
  } as React.CSSProperties,
  crossIcon: {
    color: "black",
    fontWeight: "bolder",
    cursor: "pointer",
  } as React.CSSProperties,
  redCross: {
    color: "red",
    fontSize: "14px",
  },
  shopifyInput: {
    float: "left",
    width: "100%",
    clear: "both",
    marginLeft: "0px",
    fontFamily: "ITC Avant Garde Gothic Std Book",
  } as React.CSSProperties,
  error: {
    color: "red",
    fontSize: "12px",
  },
};

const theme = createTheme();

const useStyles = makeStyles({
  inputField: {
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  modalContainer: {
    width: "530px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    height: "max-content",
    backgroundColor: "white",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
  },
});
