import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CopyIcon from "./CopyIcon";
import PasteIcon from "./PasteIcon";
import SendIcon from "./SendIcon";

function Index() {
  const classes = useStyles();
  const location = useLocation();
  const [otp, setOtp] = useState<string>("000000");

  useEffect(() => {
    let authCode = new URLSearchParams(location.search).getAll("otp");
    if (authCode && authCode.length > 0) {
      setOtp(authCode[0]);
    }
  }, [location.search]);

  const [copied, setCopied] = useState(false);
  const classesArray = ["b", "o", "u", "n", "c", "e"];

  return (
    <div className={classes.root}>
      <div className={classes.optBox}>
        <div className={classes.topIcon}>
          <SendIcon />
        </div>
        <p className={classes.headerText}>
          It's one time generated code, will be used only once. Do not share this passsword with
          anyone.
        </p>
        <div
          style={{ display: "flex", marginBottom: "40px" }}
          className={`${copied ? "bouncing-text" : ""}`}
        >
          {otp
            .toString()
            .split("")
            .map((o, i) => (
              <div
                key={i}
                style={{
                  borderBottom: "2px solid black",
                  marginRight: i === 2 ? "20px" : "5px",
                }}
              >
                <div key={i} className={`${classes.otp} ${copied ? classesArray[i] : ""}`}>
                  {o}
                </div>
              </div>
            ))}
          <div className="shadow"></div>
          <div className="shadow-two"></div>
        </div>
        <div className={classes.btnBox}>
          <button className={classes.capablBtn}>
            <a href="/" rel="noreferrer" target="_new">
              Go to Capabl
            </a>
          </button>
          <button
            className={classes.copyBtn}
            onClick={() => {
              navigator.clipboard.writeText(`${otp}`);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 1000);
            }}
          >
            {copied ? <PasteIcon /> : <CopyIcon />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Index;

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  optBox: {
    width: "700px",
    height: "440px",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
  },
  otp: {
    fontSize: "80px",
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    cursor: "pointer",
    margin: "20px 0px",
    display: "flex",
    height: 70,
  },
  headerText: {
    fontSize: "18px",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    width: "470px",
    textAlign: "center",
  },
  copyBtn: {
    border: "1px solid #7565A1",
    color: "#7565A1",
    width: "15%",
    height: "40px",
    borderRadius: "5px",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    "&:hover": {
      backgroundColor: "#7565A1",
      color: "#ffffff",
    },
  },
  capablBtn: {
    backgroundColor: "#7565A1",
    width: "80%",
    height: "40px",
    borderRadius: "5px",
    color: "#ffffff",
    fontFamily: "ITC Avant Garde Gothic Std Book",
  },
  btnBox: {
    width: "320px",
    display: "flex",
    justifyContent: "space-between",
  },
  topIcon: {
    width: "70px",
    height: "70px",
    borderRadius: "100px",
    backgroundColor: "rgb(117, 101, 161)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
});
