import { createTheme } from "@mui/material/styles";

export const dashboardTheme = createTheme({
  spacing: 2,
  palette: {
    primary: {
      main: "#7565A1",
    },
  },
  typography: {
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    h1: {
      fontFamily: "ITC Avant Garde Gothic Std Medium",
      fontSize: 26,
      fontWeight: 400,
      color: "#000",
      textAlign: "left",
    },
    h2: {
      fontFamily: "ITC Avant Garde Gothic Std Medium",
      fontSize: 18,
      fontWeight: 400,
      color: "#000",
      textAlign: "left",
    },
    h3: {
      fontFamily: "ITC Avant Garde Gothic Std Medium",
      fontSize: 14,
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.6)",
      textAlign: "left",
    },
    h4: {
      fontFamily: "ITC Avant Garde Gothic Std Medium",
      fontSize: 24,
      fontWeight: 400,
      color: "#000",
      textAlign: "left",
    },
    h5: {
      fontFamily: "ITC Avant Garde Gothic Std Medium",
      fontSize: 44,
      fontWeight: 400,
      color: "#000",
      textAlign: "left",
    },
    body1: {
      fontFamily: "ITC Avant Garde Gothic Std Medium",
      fontSize: 12,
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.6)",
      textAlign: "left",
    },
    body2: {
      fontFamily: "ITC Avant Garde Gothic Std Medium",
      fontSize: 14,
      fontWeight: 400,
      color: "#000000",
      textAlign: "left",
    },
  },
});

export const loaderOptions = {
  maskColor: "transparent",
  color: "#1565C0",
  textColor: "#1565C0",
  text: "",
};

export type typography =
  | "inherit"
  | "button"
  | "overline"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | undefined;
