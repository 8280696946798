import { createTheme } from "@mui/material/styles";
import { dashboardTheme } from "../UpdatedLandPage/theme";

export const reportsTheme = createTheme({
  ...dashboardTheme,
  spacing: 8,
  typography: {
    ...dashboardTheme.typography,
    h6: {
      fontFamily: "ITC Avant Garde Gothic Std Medium",
      fontSize: 20,
      fontWeight: 500,
      color: "#000",
      textAlign: "left",
    },
    subtitle1: {
      fontFamily: "ITC Avant Garde Gothic Std Medium",
      fontSize: 16,
      fontWeight: 400,
      color: "#000",
      textAlign: "left",
      lineHeight: 1.1,
    },
    body2: {
      ...dashboardTheme.typography.body2,
      color: "rgba(0, 0, 0, 0.6)",
    },
    // h3: {
    //   ...dashboardTheme.typography.h3,
    //   lineHeight: "none",
    // },
  },
});

export const loaderOptions = {
  maskColor: "transparent",
  color: "#1565C0",
  textColor: "#1565C0",
  text: "",
};
