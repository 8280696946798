import { CircularProgress, ThemeProvider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "./NewTheme";

export const Loader = (props: { content?: string }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className="flex flex-col justify-center items-center h-full">
          <Typography variant="overline">{props.content || ""}</Typography>
          <CircularProgress />
        </div>
      </div>
    </ThemeProvider>
  );
};

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -45%)",
    backgroundColor: "#fff",
    width: "100%",
    height: "90vh",
    zIndex: 2000,
  },
});
