import { AlertColor } from "@mui/material";
import { signIn } from "../utils/AuthWrapper";
import { fetchUserByEmailFromDB, GetShops } from "./providerServices";
import { USER_EMAIL, USER_STATUS } from "../utils/const";
import { Role, ShopType } from "../generated/graphql";
import { Crisp } from "crisp-sdk-web";

interface SignInProps {
  emailAddress: string;
  password: string;
  token?: string;
  shopName?: string;
  setLoadings: (flag: boolean) => void;
  loginProps?: {
    showAlert: (message: string, severity: AlertColor, open: boolean) => void;
    isShopInprocess: () => void;
  };
  history: { push: (arg: string) => void; location: { search: string } };
  dispatch?: (action: any) => void;
  setStep?: React.Dispatch<React.SetStateAction<number>>;
}

export async function userSignIn(props: SignInProps) {
  if (!props.emailAddress || !props.password) {
    props.loginProps &&
      props.loginProps.showAlert &&
      props.loginProps.showAlert("Invalid email or password!", "error", true);
    return;
  }

  props.setLoadings(true);
  try {
    var signedUser = await signIn(props.emailAddress, props.password, props);
    Crisp.user.setEmail(signedUser["email"]);
    Crisp.user.setCompany(signedUser["companyName"], {});
    Crisp.user.setNickname(signedUser["firstName"] + " " + signedUser["lastName"]);
    if (window && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "login",
        user_email: signedUser["email"],
        user_company: signedUser["companyName"],
        user_name: signedUser["firstName"] + " " + signedUser["lastName"],
      });
    }
    console.log(signedUser, "signedUser");
    if (signedUser !== null) {
      localStorage.setItem(USER_EMAIL, JSON.stringify(signedUser["email"]));
      localStorage.setItem(USER_STATUS, JSON.stringify("active"));
      try {
        console.log("in try login");
        const userFromDB = await fetchUserByEmailFromDB();
        if (userFromDB) {
          props.dispatch && props.dispatch({ type: "UPDATE_USER_INFO", payload: userFromDB });
          console.log("after fetchUserByEmailFromDB login");
          const shops: { getShops: Array<{ name: string; domain: string; shopType: ShopType }> } =
            await GetShops();
          const isExist = shops.getShops.filter((shop: any) => shop.pricingPlan !== "LITE");
          console.log({ shops, isExist }, "shops in singnin");
          // const isExist = [];
          props.loginProps &&
            props.loginProps.showAlert &&
            props.loginProps.showAlert("Signed in successfully!", "success", true);
          props.setLoadings(false);
          props.loginProps &&
            props.loginProps.isShopInprocess &&
            props.loginProps.isShopInprocess();
          if (props.shopName && props.token && isExist.length) {
            console.log("inside create push");
            props.history.push("/dashboard/create-shop" + props.history.location.search);
            loadExistingUserData(signedUser, props);
          } else if (props.shopName && props.token && isExist.length === 0) {
            props.dispatch && props.dispatch({ type: "CHANGE_STEP_COUNT", payload: 1 });
            props.setStep && props.setStep(1);
          }
          // else if (isExist.length === 0) {
          //   props.setStep &&
          //     props.setStep((prev: number) => {
          //       props.dispatch && props.dispatch({ type: "CHANGE_STEP_COUNT", payload: prev + 1 });
          //       return prev + 1;
          //     });
          // }
          else {
            console.log("inside dashboard, empty query params");
            props.dispatch && props.dispatch({ type: "QUERY_PARAM_SHOP_NAME", payload: "" });
            props.dispatch && props.dispatch({ type: "QUERY_PARAM_TOKEN_NAME", payload: "" });
            props.history.push("/dashboard");
            loadExistingUserData(signedUser, props);
          }
        } else {
          let userData = {
            comp_name: signedUser.companyName,
            email: signedUser.email,
            created_at: "",
            cust_id: 0,
            role: Role.Admin,
            id: -1,
            status: "ACTIVE",
            deleted_at: null,
            firstName: signedUser.firstName,
            lastName: signedUser.lastName,
          };
          console.log("=====> in try login else");
          props.dispatch && props.dispatch({ type: "SET_ONBOADING_USER", payload: userData });
          // loadExistingUserData(signedUser, props);
          props.dispatch && props.dispatch({ type: "CHANGE_STEP_COUNT", payload: 1 });

          props.setStep && props.setStep(1);
        }
      } catch (error) {
        console.log("in catch login");
        // props.loginProps &&
        //   props.loginProps.showAlert &&
        //   props.loginProps.showAlert("Email not verified!", "error", true);
        // props.setLoadings(false);
        // props.history.push("/onboarding" + props.history.location.search);
        // loadExistingUserData(signedUser, props);
      }
    }
  } catch (error) {
    console.error(error);
    props.setLoadings(false);
  }
}

function loadExistingUserData(signedUser: any, props: any) {
  const userDataExist = JSON.parse(localStorage.getItem("onboard_" + signedUser["email"]) || "{}");
  if (userDataExist) {
    props.dispatch &&
      props.dispatch({
        type: "SET_EXISTING_USER_DATA_FROM_LOCALSTORAGE",
        payload: userDataExist,
      } as any);
  }
}
