import { List, ListItem, ListItemIcon, Typography } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CurvedArrow from "../../../Resources/onboarding/curved_arrow.png";
import { OnboardingInintState } from "../../../store/reducers/onboarding";
import { signUpRightContent, loginRightContent } from "../data";

const HiddenDiv: any = Hidden;

function Index({ step }: { step: number }) {
  const { onboardingUser }: OnboardingInintState = useSelector((state: any) => state.onboarding);
  const history = useHistory();
  const currentStep =
    history.location.pathname === "/"
      ? loginRightContent[step || 0]
      : signUpRightContent[step || 0];

  return (
    <div className="relative pt-8">
      <HiddenDiv lgDown>
        <div className="absolute right-0 -top-0 ">
          <img src={CurvedArrow} width={250} alt="Avatar" />
        </div>
      </HiddenDiv>
      <div className=" w-3/4">
        <Typography variant={step === 0 ? "h3" : "h5"}>
          {currentStep.title} {step === 4 ? onboardingUser?.firstName + "!" : ""}
        </Typography>
        <Typography variant="body2" className="pb-8 pt-5">
          {currentStep.subtitle}
        </Typography>
      </div>
      <List>
        {currentStep &&
          currentStep.list.map((item: any, index: number) => (
            <ListItemComponent item={item} key={index} />
          ))}
      </List>
    </div>
  );
}

export default Index;

const ListItemComponent = ({
  item,
}: {
  item: { icon: string; subtitle: string; caption: string };
}) => {
  return (
    <ListItem className="mt-8 flex flex-row" style={{ alignItems: "flex-start" }}>
      <ListItemIcon className=" mt-2.5">
        <img src={item.icon} height={18} alt="Avatar" width={18} />
      </ListItemIcon>
      <div className="flex flex-col">
        <Typography variant="subtitle1">{item.subtitle}</Typography>
        <Typography variant="caption">{item.caption}</Typography>
      </div>
    </ListItem>
  );
};
