import { USER_EMAIL, USER_STATUS } from "./const";

/**
 * AWS config for Amplify usage
 */
export interface AWSConfig {
  readonly awsRegion: string;
  readonly userPoolId: string;
  readonly userPoolWebClientId: string;
  readonly authenticationFlowType: "USER_SRP_AUTH";
  readonly mandatorySignIn: false;
}

/**
 * All values in local storage are filled in this interface.
 * Please call {@link loadAndGetLocalStorage} to get values.
 */
export interface LocalStorage {
  readonly customerId: string | null;
  readonly awsConfig: AWSConfig;
}

const awsConfigKey = "awsConfig";

/**
 * The local storage object and abstracted
 */
export let browserLocalStorage: LocalStorage;

/**
 * Overwrites the localStorage object and returns a new one.
 * @returns Returns {@link LocalStorage}
 */
export const loadAndGetLocalStorage = (): Promise<LocalStorage> => {
  return new Promise<LocalStorage>((resolve, _) => {
    const customerId = localStorage.getItem(awsConfigKey);
    const awsConfig: AWSConfig = customerId
      ? JSON.parse(localStorage.getItem(awsConfigKey) ?? "")
      : null;
    browserLocalStorage = {
      customerId: customerId,
      awsConfig: awsConfig,
    };
    resolve(browserLocalStorage);
  });
};

/**
 * Clears all the local storage values.
 */
export const clearLocalStorage = () => {
  localStorage.removeItem(browserLocalStorage.customerId ?? "");
  localStorage.removeItem(awsConfigKey);
  localStorage.removeItem(USER_STATUS);
};

/**
 *
 * @param awsConfig Stores the provided aws config.
 */
export const storeAWSConfig = (awsConfig: AWSConfig) => {
  localStorage.setItem(awsConfigKey, JSON.stringify(awsConfig));
};

/**
 * Clears specific values from local storage to display BI tools refreshed values..
 */
export const clearLocalStorageToLoadBI = () => {
  if (localStorage.getItem(USER_EMAIL)) {
    let userEamil = JSON.parse(localStorage.getItem(USER_EMAIL) as any);
    localStorage.removeItem(userEamil + "_biTime");
    localStorage.removeItem(userEamil + "_time");
    localStorage.removeItem(userEamil + "_trends");
  }
};
