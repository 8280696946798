import { makeStyles } from "@mui/styles";
import CapablLogo from "./Resources/onboarding/cap-logo.png";

export default function SplashLoader() {
  const classes = useStyles();

  return (
    <div className={`flex flex-row justify-center items-center ${classes.root}`}>
      <img src={CapablLogo} alt="Loader" className="loading" />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
  },
});
