import { RoutesType } from "../Components/Onboarding/FCRouting";

export const timezonesList = [
  { value: -12, title: "(GMT -12:00) Eniwetok, Kwajalein" },
  { value: -11, title: "(GMT -11:00) Midway Island, Samoa" },
  { value: -10, title: "(GMT -10:00) Hawaii" },
  { value: -9.5, title: "(GMT -9:30) Taiohae" },
  { value: -9, title: "(GMT -9:00) Alaska" },
  { value: -8, title: "(GMT -8:00) Pacific Time (US & Canada)" },
  { value: -7, title: "(GMT -7:00) Mountain Time (US & Canada)" },
  { value: -6, title: "(GMT -6:00) Central Time (US & Canada), Mexico City" },
  { value: -5, title: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima" },
  { value: -4.5, title: "(GMT -4:30) Caracas" },
  { value: -4, title: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz" },
  { value: -3.5, title: "(GMT -3:30) Newfoundland" },
  { value: -3, title: "(GMT -3:00) Brazil, Buenos Aires, Georgetown" },
  { value: -2, title: "(GMT -2:00) Mid-Atlantic" },
  { value: -1, title: "(GMT -1:00) Azores, Cape Verde Islands" },
  { value: 0, title: "(GMT) Western Europe Time, London, Lisbon, Casablanca" },
  { value: 1, title: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris" },
  { value: 2, title: "(GMT +2:00) Kaliningrad, South Africa" },
  { value: 3, title: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg" },
  { value: 3.5, title: "(GMT +3:30) Tehran" },
  { value: 4, title: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi" },
  { value: 4.5, title: "(GMT +4:30) Kabul" },
  { value: 5, title: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent" },
  { value: 5.5, title: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi" },
  { value: 5.75, title: "(GMT +5:45) Kathmandu, Pokhara" },
  { value: 6, title: "(GMT +6:00) Almaty, Dhaka, Colombo" },
  { value: 6.5, title: "(GMT +6:30) Yangon, Mandalay" },
  { value: 7, title: "(GMT +7:00) Bangkok, Hanoi, Jakarta" },
  { value: 8, title: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong" },
  { value: 8.75, title: "(GMT +8:45) Eucla" },
  { value: 9, title: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk" },
  { value: 9.5, title: "(GMT +9:30) Adelaide, Darwin" },
  { value: 10, title: "(GMT +10:00) Eastern Australia, Guam, Vladivostok" },
  { value: 10.5, title: "(GMT +10:30) Lord Howe Island" },
  { value: 11, title: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia" },
  { value: 11.5, title: "GMT +11:30) Norfolk Island" },
  { value: 12, title: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka" },
  { value: 12.75, title: "(GMT +12:45) Chatham Islands" },
  { value: 13, title: "(GMT +13:00) Apia, Nukualofa" },
  { value: 14, title: "(GMT +14:00) Line Islands, Tokelau" },
];

export const array_states = [
  "Alabama (AL)",
  "Alaska (AK)",
  "Arizona (AZ)",
  "Arkansas (AR)",
  "California (CA)",
  "Colorado (CO)",
  "Connecticut (CT)",
  "Delaware (DE)",
  "District of Columbia (DC)",
  "Florida (FL)",
  "Georgia (GA)",
  "Hawaii (HI)",
  "Idaho (ID)",
  "Illinois (IL)",
  "Indiana (IN)",
  "Iowa (IA)",
  "Kansas (KS)",
  "Kentucky (KY)",
  "Louisiana (LA)",
  "Maine (ME)",
  "Maryland (MD)",
  "Massachusetts (MA)",
  "Michigan (MI)",
  "Minnesota (MN)",
  "Mississippi (MS)",
  "Missouri (MO)",
  "Montana (MT)",
  "Nebraska (NE)",
  "Nevada (NV)",
  "New Hampshire (NH)",
  "New Jersey (NJ)",
  "New Mexico (NM)",
  "New York (NY)",
  "North Carolina (NC)",
  "North Dakota (ND)",
  "Ohio (OH)",
  "Oklahoma (OK)",
  "Oregon (OR)",
  "Pennsylvania (PA)",
  "Rhode Island (RI)",
  "South Carolina (SC)",
  "South Dakota (SD)",
  "Tennessee (TN)",
  "Texas (TX)",
  "Utah (UT)",
  "Vermont (VT)",
  "Virginia (VA)",
  "Washington (WA)",
  "West Virginia (WV)",
  "Wisconsin (WI)",
  "Wyoming (WY)",
];

export const array_countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre & Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts & Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export function getRouteTypeByData(input: string) {
  if (input === "") {
    return RoutesType.STATES;
  }

  return array_states.indexOf(input) !== -1
    ? RoutesType.STATES
    : array_countries.indexOf(input) !== -1
    ? RoutesType.COUNTRIES
    : RoutesType.ZIP;
}

export const ScorecardNodeId = "scorecard-component";

export const DEFAULT_WORK_DAYS = "Mon,Tue,Wed,Thu,Fri";

export const TRIAL_DAYS = 30;

export const UrlsList = {
  "/dashboard": 0,
  "/dashboard/scorecard": 1,
  "/dashboard/orders": 2,
  "/dashboard/bi-view": 3,
  "/dashboard/admin-settings": 5,
  "/dashboard/create-shop": 6,
};
