import { BiTableId } from "../../generated/graphql";
import { types } from "../types";

const { BI_VIEW_DATA_LOADING, BI_VIEW_DATA_FAILED, BI_VIEW_WEEKLY_COLUMNS, SAVE_REPORT_DATA } =
  types;

/** INITIAL STATES FOR ROOT REDUCER */
export const initState: BIViewInitStateInterface = {
  loader: false,
  time: { weekly: [], monthly: [], quarter: [], dailyInternal: [] },
  reportBiQuery: [BiTableId.ZoneDistribution],
  reportTitle: "",
  title: "",
  desc: "",
};

export const biReducer = (state = initState, action: ActionInterface) => {
  switch (action.type) {
    /** SUCCESS ACTION WILL EFFECT THROUGH BELOW CASES */
    case BI_VIEW_DATA_LOADING:
      return {
        ...state,
        loader: true,
      };

    case BI_VIEW_WEEKLY_COLUMNS:
      return {
        ...state,
        time: action.payload,
      };

    case SAVE_REPORT_DATA:
      return {
        ...state,
        title: action.payload.title,
        desc: action.payload.desc,
      };

    /** FAILED ACTION WILL EFFECT THROUGH BELOW CASES */
    case BI_VIEW_DATA_FAILED:
      return {
        ...state,
        loader: false,
      };

    /** DEFAULT CASE */
    default:
      return state;
  }
};

interface ActionInterface {
  type: string;
  payload: any;
}

export interface BIViewInitStateInterface {
  loader: boolean;
  time: { weekly: string[]; monthly: string[]; quarter: string[]; dailyInternal: string[] };
  reportBiQuery: BiTableId[];
  reportTitle: string;
  title: string;
  desc: string;
}
