import { Close } from "@mui/icons-material";
import { Modal, TextField, Button, createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { sendHelpEmail } from "../../utils/providerServices";
import { verifyEmail } from "../../utils/validations";

interface HelpButtonProps {
  open: boolean;
  subject: string;
  setOpen: React.Dispatch<React.SetStateAction<any>>;
}

export const HelpButton = ({ open, subject, setOpen }: HelpButtonProps) => {
  const classes = useStyles();
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [email, setEmail] = useState("");
  const [touched, setTouched] = useState({ emailSubject: false, emailBody: false, email: false });

  const sendEmail = async () => {
    await sendHelpEmail(emailBody, emailSubject, email);
    setOpen(false);
    closePopup();
  };

  const closePopup = () => {
    setEmailSubject("");
    setEmailBody("");
    setEmail("");
    setTouched({ emailSubject: false, emailBody: false, email: false });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          closePopup();
        }}
      >
        <div className={`${classes.root} p-5`}>
          <div
            className="cursor-pointer text-right"
            onClick={() => {
              setOpen(false);
              closePopup();
            }}
          >
            <Close />
          </div>
          <div className=" flex flex-col items-center rounded">
            <div className={classes.tagsInputContainerUpdate}>
              <div className={classes.labelContainerUpdate}>
                <p className={classes.label}>Email</p>
              </div>
              <TextField
                name="email"
                type="email"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                className={`${classes.inputField} ${classes.fullWidthInput}`}
                aria-readonly={true}
                onBlur={() => setTouched((prev) => ({ ...prev, email: true }))}
              />
              {touched.email && email === "" && (
                <small className={classes.error}>email is required</small>
              )}
            </div>
            <div className={classes.tagsInputContainerUpdate}>
              <div className={classes.labelContainerUpdate}>
                <p className={classes.label}>Subject</p>
              </div>
              <TextField
                type="text"
                name="email_subject"
                value={emailSubject}
                onChange={({ target }) => setEmailSubject(target.value)}
                className={`${classes.inputField} ${classes.fullWidthInput}`}
                aria-readonly={true}
                onBlur={() => setTouched((prev) => ({ ...prev, emailSubject: true }))}
              />
              {touched.emailSubject && emailSubject === "" && (
                <small className={classes.error}>subject is required</small>
              )}
            </div>
            <div className={classes.tagsInputContainerUpdate}>
              <div className={classes.labelContainerUpdate}>
                <p className={classes.label}>Message</p>
              </div>
              <textarea
                name="email_body"
                onChange={({ target }) => setEmailBody(target.value)}
                rows={3}
                value={emailBody}
                className={`${classes.textarea}`}
                aria-readonly={true}
                onBlur={() => setTouched((prev) => ({ ...prev, emailBody: true }))}
              ></textarea>
              {touched.emailBody && emailBody === "" && (
                <small className={classes.error}>message is required</small>
              )}
            </div>
            <Button
              color="primary"
              disabled={
                email === "" || emailBody === "" || emailSubject === "" || !verifyEmail(email)
              }
              onClick={() => {
                if (verifyEmail(email) && emailBody !== "") {
                  sendEmail();
                }
              }}
              className={
                email === "" || emailBody === "" || emailSubject === "" || !verifyEmail(email)
                  ? classes.disablebtn
                  : classes.btn
              }
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const theme = createTheme();

const useStyles = makeStyles({
  root: {
    width: "500px",
    minHeight: "300px",
    left: "50%",
    top: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  tagsInputContainerUpdate: {
    width: "100%",
  },
  labelContainerUpdate: {
    marginTop: "20px",
  },
  label: {
    color: "#435470",
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "10px",
  },
  inputField: {
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
  },
  fullWidthInput: {
    width: "100%",
  },
  textarea: {
    border: "1px solid lightgrey",
    width: "100%",
    borderRadius: "4px",
    padding: "12px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid lightgrey",
    },
  },
  btn: {
    backgroundColor: "rgb(7, 169, 91)",
    height: "40px",
    borderRadius: "4px",
    fontSize: "14px",
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    color: "#ffffff",
    width: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "rgb(7, 169, 91)",
    },
  },
  disablebtn: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)",
    height: "40px",
    borderRadius: "4px",
    fontSize: "14px",
    fontFamily: "ITC Avant Garde Gothic Std Medium",
    width: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
  error: {
    color: "red",
  },
});
