import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TextField from "../../../Common/TextField/index";
import { LoginFormProps, useStyles } from "../../styles";
import Title from "../../Main/Title/Index";
import { useDispatch } from "react-redux";
import React from "react"

function Index(props: LoginFormProps) {
  const { formik } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className="p-7">
      <Title style={{ marginBottom: 39 }}>
        <>
          Access your account with capabl
          {/* - <span className={classes.link}>Its free!</span> */}
        </>
      </Title>
      {arrayFields.map((field, index) => (
        <React.Fragment key={index}>
          <TextField
            {...field}
            {...formik.getFieldProps(field.name)}
            formik={formik}

            className={field.name === "password" ? classes.textFieldPassword : classes.textField}
          />
          {field.name === "password" && (
            <Typography
              onClick={() => dispatch({ type: "SHOW_FORGOT_PASSWORD", payload: true })}
              className={classes.forgotPasswordContainer}
            >
              Forgot Password?
            </Typography>
          )}
        </React.Fragment>
      ))}
      <Button color="primary" onClick={formik.handleSubmit} className={classes.accountBtn}>
        Login
      </Button>
      <Typography variant="body2" align="center">
        Don't have an account?{" "}
        <Link to="/signup" className={classes.link}>
          Create
        </Link>
      </Typography>
    </div>
  );
}

export default Index;

const arrayFields = [
  { name: "email", label: "Email", type: "text" },
  { name: "password", label: "Password", type: "text", isPassword: true },
];
