export const verifyPassword = (pass: string) => {
  if (pass.trim().length === 0 || pass.length < 8) {
    return false;
  }
  return true;
};

export const verifyEmail = (email: string) => {
  var re = /(.+)@(.+){2,}\.(.+){2,}/;
  if (email.trim().length === 0) {
    return false;
  }
  if (email.indexOf(" ") >= 0) {
    return false;
  }
  return re.test(email);
};

export const stringDotRemover = (input: string) => {
  return input.split(".").join("");
};
