import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";

function SearchField({
  value,
  setValue,
  placeholder,
}: {
  value: string;
  setValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}) {
  const classes = useStyles();

  return (
    <TextField
      value={value}
      InputProps={{
        endAdornment: <SearchIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />,
      }}
      onChange={setValue}
      label={placeholder || "Search in reports"}
      className="mt-4 text-base"
      classes={{ root: classes.root }}
    />
  );
}

export default SearchField;

const useStyles = makeStyles({
  root: {
    width: 250,
    marginTop: 10,
    fontSize: 14,
    "& .MuiInputLabel-root": {
      fontSize: 14,
    },
    "& .Mui-Focused.MuiInputLabel-root": {
      fontSize: 12,
    },
  },
});
