import { getDateRangeFromItem } from "../../../../utils/dateformatter";

export enum CustomReportType {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  DAY_OF_WEEK = "DAY_OF_WEEK",
  NONE = "NONE",
}

export enum CustomReportPeriodType {
  CUSTOM = "CUSTOM",
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  LAST_SEVEN_DAYS = "LAST_SEVEN_DAYS",
  LAST_THIRTY_DAYS = "LAST_THIRTY_DAYS",
  LAST_NINETY_DAYS = "LAST_NINETY_DAYS",
  LAST_MONTH = "LAST_MONTH",
  LAST_YEAR = "LAST_YEAR",
}

export enum CustomReportComparisonType {
  NONE = "NONE",
  PREVIOUS_PERIOD = "PREVIOUS_PERIOD",
  PREVIOUS_YEAR = "PREVIOUS_YEAR",
}

export function getCustomReportPeriodType(type: string) {
  switch (type) {
    case "Last 7 days":
      return CustomReportPeriodType.LAST_SEVEN_DAYS;
    case "Last month":
      return CustomReportPeriodType.LAST_MONTH;
    case "Last 90 days":
      return CustomReportPeriodType.LAST_NINETY_DAYS;
    case "Last 30 days":
      return CustomReportPeriodType.LAST_THIRTY_DAYS;
    case "This year till yesterday":
      return CustomReportPeriodType.LAST_YEAR;
    case "Today":
      return CustomReportPeriodType.TODAY;
    case "Yesterday":
      return CustomReportPeriodType.YESTERDAY;
    default:
      return CustomReportPeriodType.CUSTOM;
  }
}

export function getCustomReportPeriodTypeString(type: string) {
  switch (type) {
    case CustomReportPeriodType.LAST_SEVEN_DAYS:
      return "Last 7 days";
    case CustomReportPeriodType.LAST_MONTH:
      return "Last month";
    case CustomReportPeriodType.LAST_NINETY_DAYS:
      return "Last 90 days";
    case CustomReportPeriodType.LAST_THIRTY_DAYS:
      return "Last 30 days";
    case CustomReportPeriodType.LAST_YEAR:
      return "This year till yesterday";
    case CustomReportPeriodType.TODAY:
      return "Today";
    case CustomReportPeriodType.YESTERDAY:
      return "Yesterday";
    default:
      return "CUSTOM";
  }
}

export function getCustomReportComparisonType(type: string) {
  switch (type) {
    case "Previous peroid":
      return CustomReportComparisonType.PREVIOUS_PERIOD;
    case "Previous year":
      return CustomReportComparisonType.PREVIOUS_YEAR;
    default:
      return CustomReportComparisonType.NONE;
  }
}

export function getCustomReportComparisonTypeString(type: string) {
  switch (type) {
    case CustomReportComparisonType.PREVIOUS_PERIOD:
      return "Previous peroid";
    case CustomReportComparisonType.PREVIOUS_YEAR:
      return "Previous year";
    default:
      return "No Comparison";
  }
}

export function getCustomReportType(type: string) {
  switch (type) {
    case "dailyInternal":
      return CustomReportType.DAILY;
    case "daysOfWeek":
      return CustomReportType.DAY_OF_WEEK;
    case "monthly":
      return CustomReportType.MONTHLY;
    case "weekly":
      return CustomReportType.WEEKLY;
    default:
      return CustomReportType.NONE;
  }
}

export function getCustomReportTypeString(type: string) {
  switch (type) {
    case CustomReportType.DAILY:
      return "dailyInternal";
    case CustomReportType.DAY_OF_WEEK:
      return "daysOfWeek";
    case CustomReportType.MONTHLY:
      return "monthly";
    case CustomReportType.WEEKLY:
      return "weekly";
    default:
      return "";
  }
}

export function getCustomDatePicker(customType: string) {
  const customShortCuts = [
    { label: "TODAY", dateRange: getDateRangeFromItem(1) },
    { label: "YESTERDAY", dateRange: getDateRangeFromItem(2) },
    { label: "LAST_SEVEN_DAYS", dateRange: getDateRangeFromItem(7) },
    { label: "LAST_THIRTY_DAYS", dateRange: getDateRangeFromItem(8) },
    { label: "LAST_MONTH", dateRange: getDateRangeFromItem(13) },
    { label: "LAST_NINETY_DAYS", dateRange: getDateRangeFromItem(9) },
    // { label: "Last 6 months", dateRange: getDateRangeFromItem(6) },
    { label: "LAST_YEAR", dateRange: getDateRangeFromItem(10) },
  ];

  return customShortCuts.filter((shortCut) => shortCut.label === customType)[0];
}
