import { getDateRangeFromItem } from "../../../../utils/dateformatter";
import { EChartsOption } from "echarts";
import { colors } from "../BiTools/Charts/ChartOptions";
import { BiTableId } from "../../../../generated/graphql";
import { decimalParser } from "../../../../utils/helper";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export const periodList = [
  { value: "dailyInternal", label: "Days" },
  { value: "daysOfWeek", label: "Day of week" },
  { value: "monthly", label: "Monthly" },
  { value: "weekly", label: "Weekly" },
];

export const compareShortCuts = [
  { label: "No Comparison", dateRange: getDateRangeFromItem(12) },
  { label: "Previous peroid", dateRange: getDateRangeFromItem(12) },
  { label: "Previous year", dateRange: getDateRangeFromItem(12) },
];

export const shortCuts = [
  { label: "Today", dateRange: getDateRangeFromItem(1) },
  { label: "Yesterday", dateRange: getDateRangeFromItem(2) },
  { label: "Last 7 days", dateRange: getDateRangeFromItem(7) },
  { label: "Last 30 days", dateRange: getDateRangeFromItem(8) },
  { label: "Last month", dateRange: getDateRangeFromItem(13) },
  { label: "Last 90 days", dateRange: getDateRangeFromItem(9) },
  // { label: "Last 6 months", dateRange: getDateRangeFromItem(6) },
  { label: "This year", dateRange: getDateRangeFromItem(10) },
];

export const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function graphOptions(
  series: any[],
  xAxis: string[],
  compareXAxis: string[],
  reportBiQuery: BiTableId,
  selectedFC: string,
  selectedSalesChannel: string
): EChartsOption {
  const seriesArray: any = [];
  const legends: any = [];
  if (reportBiQuery === "ON_TIME_FULFILMENT_PER_FC_PER_SC") {
    if (selectedFC === "All Fulfilment Centers") {
      if (selectedSalesChannel !== "All Sales Channels") {
        series[0].forEach((_: any, index: number) => {
          if (_.scName === selectedSalesChannel) {
            legends.push(_.name);
            seriesArray.push({
              name: _.name,
              data: _.data,
              type: "line",
              smooth: true,
              symbol: "circle",
              color: colors[index],
              showSymbol: false,
              lineStyle: {
                color: colors[index],
              },
            });
          }
        });
      } else {
        series[0].forEach((_: any, index: number) => {
          legends.push(_.name);
          seriesArray.push({
            name: _.name,
            data: _.data,
            type: "line",
            smooth: true,
            symbol: "circle",
            color: colors[index],
            showSymbol: false,
            lineStyle: {
              color: colors[index],
            },
          });
        });
      }
    } else {
      if (selectedSalesChannel !== "All Sales Channels") {
        series[0].forEach((_: any, index: number) => {
          if (_.scName === selectedSalesChannel) {
            if (_.name.includes(selectedFC)) {
              legends.push(_.name);
              seriesArray.push({
                name: _.name,
                data: _.data,
                type: "line",
                smooth: true,
                symbol: "circle",
                color: colors[index],
                showSymbol: false,
                lineStyle: {
                  color: colors[index],
                },
              });
            }
          }
        });
      } else {
        series[0].forEach((_: any, index: number) => {
          if (_.name.includes(selectedFC)) {
            legends.push(_.name);
            seriesArray.push({
              name: _.name,
              data: _.data,
              type: "line",
              smooth: true,
              symbol: "circle",
              color: colors[index],
              showSymbol: false,
              lineStyle: {
                color: colors[index],
              },
            });
          }
        });
      }
    }
  } else if (
    reportBiQuery === "SHIPPED_TO_DELIVERED" ||
    reportBiQuery === "PARCEL_EXPENSE" ||
    reportBiQuery === "AVG_SHIPMENT_WEIGHTS" ||
    reportBiQuery === "MDN_SHIPMENT_WEIGHTS" ||
    reportBiQuery === "ZONE_DISTRIBUTION" ||
    reportBiQuery === "SHIPMENT_BY_FC" ||
    reportBiQuery === "ORDER_TO_SHIPMENT" ||
    reportBiQuery === "AVG_SHIPMENT_PER_DAY" ||
    reportBiQuery === "AVG_ORDER_PER_DAY" ||
    reportBiQuery === "ON_TIME_FULFILLMENT_PER_FC" ||
    reportBiQuery === "COMPLAINTS_COUNT_PER_FC" ||
    reportBiQuery === "ACCURACY_PERCENTAGE_FC" ||
    reportBiQuery === "COMPLAINTS_PER_REASON"
  ) {
    if (selectedFC === "All Fulfilment Centers") {
      series[0].forEach((_: any, index: number) => {
        legends.push(_.title);
        seriesArray.push({
          name: _.title,
          data: _.data,
          type: "line",
          smooth: true,
          symbol: "circle",
          color: colors[index],
          showSymbol: false,
          lineStyle: {
            color: colors[index],
          },
        });

        if (series[1][index]) {
          legends.push(_.title + " Compared");
          seriesArray.push({
            name: series[1][index]["title"] + " Compared",
            data: series[1][index]["data"],
            type: "line",
            smooth: true,
            symbol: "circle",
            color: colors[index],
            showSymbol: false,
            lineStyle: {
              type: "dashed",
              width: 2,
              cap: "square",
              color: colors[index],
            },
          });
        }
      });
    } else {
      series[0].forEach((_: any, index: number) => {
        if (selectedFC === _.title) {
          legends.push(_.title);
          seriesArray.push({
            name: _.title,
            data: _.data,
            type: "line",
            smooth: true,
            symbol: "circle",
            color: colors[index],
            showSymbol: false,
            lineStyle: {
              color: colors[index],
            },
          });

          if (series[1][index]) {
            legends.push(_.title + " Compared");
            seriesArray.push({
              name: series[1][index]["title"] + " Compared",
              data: series[1][index]["data"],
              type: "line",
              smooth: true,
              symbol: "circle",
              color: colors[index],
              showSymbol: false,
              lineStyle: {
                type: "dashed",
                width: 1,
                cap: "square",
                color: colors[index],
              },
            });
          }
        }
      });
    }
  } else if (
    reportBiQuery === "ACCURACY_PERCENTAGE" ||
    reportBiQuery === "AVG_ORDER_VALUE" ||
    reportBiQuery === "AVG_BASKET_SIZE" ||
    reportBiQuery === "ORDER_TO_DELIVERED"
  ) {
    series[0].forEach((_: any, index: number) => {
      legends.push(_.title);
      seriesArray.push({
        name: _.title,
        data: _.data,
        type: "line",
        smooth: true,
        symbol: "circle",
        color: colors[index],
        showSymbol: false,
        lineStyle: {
          color: colors[index],
        },
      });

      if (series[1][index]) {
        legends.push(_.title + " Compared");
        seriesArray.push({
          name: series[1][index]["title"] + " Compared",
          data: series[1][index]["data"],
          type: "line",
          smooth: true,
          symbol: "circle",
          color: colors[index],
          showSymbol: false,
          lineStyle: {
            type: "dashed",
            width: 1,
            cap: "square",
            color: colors[index],
          },
        });
      }
    });
  } else if (reportBiQuery === "TOTAL_SHIPPED_ORDERS") {
    series.forEach((_: any, index: number) => {
      seriesArray.push({
        name: "First " + index,
        data: _,
        type: "line",
        smooth: true,
        symbol: "circle",
        color: "#5a8ec5",
        showSymbol: false,
        lineStyle: {
          color: "#5a8ec5",
        },
      });
    });
  } else if (
    reportBiQuery === "SHIPMENT_BY_TRAC" || // line & fc
    reportBiQuery === "SHIPMENT_BY_SKU" || // line
    reportBiQuery === "DOs_BY_SKU" // line
  ) {
    series[0].forEach((_: any, index: number) => {
      legends.push(_.title);
      seriesArray.push({
        name: _.title,
        type: "bar",
        barMaxWidth: 23,
        stack: "stack",
        emphasis: {
          focus: "series",
        },
        data: _.data,
        smooth: true,
        color: colors[index],
        showSymbol: false,
        lineStyle: {
          color: colors[index],
        },
      });
    });
  }

  return {
    xAxis: {
      boundaryGap: true,
      type: "category",
      data: xAxis,
      axisLabel: {
        color: "#fff",
      },
    },
    dataZoom: [
      {
        type: "inside",
      },
    ],
    tooltip: {
      trigger: "axis",
      formatter: function (params: any) {
        let length = compareXAxis.length;
        let output = length ? "" : `${params[0].axisValueLabel ?? params[0].axisValue}<br/>`;
        params.map((param: any, index: number) => {
          const dataIndex = params[0].dataIndex;
          let name;
          if (length) {
            if (param.seriesName.endsWith("Compared")) {
              name = compareXAxis[dataIndex];
            } else {
              name = param.name;
            }
          } else {
            name = param.name;
          }
          if (+param.data > 0) {
            output += `<div style='display: flex; flex-direction: row; justify-content: space-between; height: 18px; align-items:center;'>
            <div style='display: flex; align-items: center'><div style='background-color:${
              param.color
            }; width: 8px; height: 8px; border-radius: 50%; margin-right: 5px'></div>
              ${param.seriesName} ${length ? " - " + name : ""}</div> 
              <div>&nbsp;&nbsp;&nbsp;${decimalParser(param.data)}</div>
              </div>`;
          }
          return param;
        });
        return output;
      },
    },
    legend: {
      show: true,
      data: legends,
      bottom: 10,
      itemGap: 20,
      orient: "horizontal",
      type: "scroll",
      pageIconColor: "#fff",
      pageTextStyle: {
        color: "#fff",
      },
      backgroundColor: "transparent",
      textStyle: {
        color: "#fff",
      },
    },
    yAxis: {
      type: "value",
      scale: true,
      axisLabel: {
        color: "#fff",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#43434E",
        },
      },
    },
    grid: {
      top: 25,
      left: 10,
      right: 20,
      bottom: 60,
      backgroundColor: "transparent",
      shadowColor: "transparent",
      containLabel: true,
    },
    series: seriesArray,
  };
}

export const ordersCard = [
  {
    title: "On time fulfillment",
    icon: AccessTimeIcon,
    desc: "Percentage of Total Orders Shipped divided by Orders that should have been shipped (Total Shipped/Total SLA orders available for shipment)",
    biQuery: BiTableId.OnTimeFulfillmentPerFc, // BiTableId.OnTimeFulfilmentPercShop,
    reportType: "default",
  },
  {
    title: "Order accuracy",
    icon: StarHalfIcon,
    desc: "Percentage of Orders Shipped Accurately out of Total Orders Shipped. (Complaints are registered using quality reason codes)",
    biQuery: BiTableId.AccuracyPercentageFc,
    reportType: "default",
  },
  {
    title: "Order delivery time",
    icon: ShoppingCartIcon,
    desc: "The time taken by the order from when it was placed to when it was delivered in days.",
    biQuery: BiTableId.OrderToDelivered, // TODO
    reportType: "default",
  },
  {
    title: "Order by Day (average)",
    icon: ShoppingCartIcon,
    desc: "Number of orders placed by customers (per day).",
    biQuery: BiTableId.AvgOrderPerDay,
    reportType: "default",
  },
  {
    title: "Shipment by Day (average)",
    icon: ShoppingCartIcon,
    desc: "Number of shipments by FC (per day)",
    biQuery: BiTableId.AvgShipmentPerDay,
    reportType: "default",
  },
  {
    title: "Order Value",
    icon: ShoppingCartIcon,
    desc: "Average order value.",
    biQuery: BiTableId.AvgOrderValue,
    reportType: "default",
  },
  {
    title: "Basket Size",
    icon: ShoppingCartIcon,
    desc: "Average basket(cart) size of orders",
    biQuery: BiTableId.AvgBasketSize,
    reportType: "default",
  },
  {
    title: "Order to Shipment (day)",
    icon: ShoppingCartIcon,
    desc: "Average number of days an FC take to ship the order ",
    biQuery: BiTableId.OrderToShipment,
    reportType: "default",
  },
  {
    title: "Shipments by FC",
    icon: ShoppingCartIcon,
    desc: "Number of shipments processed by an FC",
    biQuery: BiTableId.ShipmentByFc,
    reportType: "default",
  },
  {
    title: "Shipments by Parcel Product",
    icon: ShoppingCartIcon,
    desc: "Shipments grouped by parcel product used",
    biQuery: BiTableId.ShipmentByTrac,
    reportType: "default",
  },
  {
    title: "Top 5 selling SKUs",
    icon: ShoppingCartIcon,
    desc: "Volume of top 5 selling SKUs.",
    biQuery: BiTableId.ShipmentBySku,
    reportType: "default",
  },
  {
    title: "Orders on Hold by SKUs (top 10)",
    icon: ShoppingCartIcon,
    desc: " Top 10 SKUs that are in delayed orders",
    biQuery: BiTableId.DOsBySku,
    reportType: "default",
  },
  {
    title: "On Time Fulfilment Percentage Per Sales Channel",
    icon: ShoppingCartIcon,
    desc: " SLA Percentage for each Sales Channel Per Fulfilment Centre",
    biQuery: BiTableId.OnTimeFulfilmentPerFcPerSc,
    reportType: "default",
  },
];
