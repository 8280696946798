import { Button, Typography } from "@mui/material";
import TextField from "../../../Common/TextField/index";
import { StepsProps, useStyles } from "../../styles";
import Title from "../Title/Index";
import { ArrowForward } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function Index(props: StepsProps) {
  const { formik } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className="p-7">
      <Title style={{ marginBottom: 19 }}>
        <>Enter your personal information</>
      </Title>
      <div className="grow flex flex-col justify-between" style={{ height: 450 }}>
        <div>
          {arrayFields.map((field, index) => (
            <TextField
              {...field}
              {...formik.getFieldProps(field.name)}
              formik={formik}
              key={index}
              className={classes.textField}
            />
          ))}
        </div>
        <Button
          onClick={() => {
            dispatch({ type: "CHANGE_STEP_COUNT", payload: 1 });
          }}
          startIcon={<ArrowForward />}
          color="primary"
          disabled={
            (formik?.errors["firstName"]?.length && formik?.touched["firstName"]) ||
            (formik?.errors["lastName"]?.length && formik?.touched["lastName"]) ||
            (formik?.errors["businessName"]?.length && formik?.touched["businessName"]) ||
            formik.values.firstName === "" ||
            formik.values.businessName === "" ||
            formik.values.lastName === ""
          }
        >
          Save and continue
        </Button>
        <Typography variant="body2" align="center">
          Already have an account?{" "}
          <Link to="/" className={classes.link}>
            Sign in
          </Link>
        </Typography>
      </div>
    </div>
  );
}

export default Index;

const arrayFields = [
  { name: "firstName", label: "First Name", type: "text" },
  { name: "lastName", label: "Last Name", type: "text" },
  { name: "businessName", label: "Business Name", type: "text" },
];
