import moment from "moment";

/* utc to local */
export function addOffset(fcOffSet: number | null | undefined, date: Date) {
  if (fcOffSet) {
    let hours = Math.floor(fcOffSet);
    let minutes = Math.floor((fcOffSet - hours) * 100);

    date = new Date(date.valueOf());
    date.setUTCHours(date.getUTCHours() + hours);
    date.setUTCMinutes(date.getUTCMinutes() + minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }
  return date;
}

/* local to utc */
export function subtractOffset(fcOffSet: number | null | undefined, date: Date) {
  if (fcOffSet) {
    let hours = Math.floor(fcOffSet);
    let minutes = Math.floor((fcOffSet - hours) * 100);

    date = new Date(date.valueOf());
    date.setUTCHours(date.getUTCHours() - hours);
    date.setUTCMinutes(date.getUTCMinutes() - minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }
  return date;
}

// tells difference between utc and current timezone in hours
export const localTimeZone = () => -(new Date().getTimezoneOffset() / 60);

/**
 *
 * FilterEventSection
 */
export function getDateRangeFromItem(index: number): any {
  // [Date | null, Date | null]
  switch (index) {
    case 1:
      // range from start of today to end of today
      return [moment().startOf("day").toDate(), moment().endOf("day").toDate()];
    case 2:
      // range from start of yesterday to end of yesterday
      const yesterday = moment().subtract(1, "day");
      return [yesterday.startOf("day").toDate(), yesterday.endOf("day").toDate()];
    case 3:
      // range from start of previous week to end of previous week
      return [
        moment().subtract(1, "weeks").startOf("week").toDate(),
        moment().subtract(1, "weeks").endOf("week").toDate(),
      ];
    case 4:
      // range from start of previous month to end of previous month
      return [
        moment().subtract(1, "months").startOf("month").toDate(),
        moment().subtract(1, "months").endOf("month").toDate(),
      ];
    case 5:
      // range from start of previous 3 months to end of previous 3 months
      return [
        moment().subtract(3, "months").startOf("month").toDate(),
        moment().subtract(1, "months").endOf("month").toDate(),
      ];
    case 6:
      // range from start of previous 6 months to end of previous 6 months
      return [
        moment().subtract(6, "months").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ];
    case 7:
      // range from start of last 7 days to end of last 7 days
      return [
        moment().subtract(7, "day").startOf("day").toDate(),
        moment().subtract(1, "day").endOf("day").toDate(),
      ];
    case 8:
      // range from start of last 7 days to end of last 7 days
      return [
        moment().subtract(30, "day").startOf("day").toDate(),
        moment().subtract(1, "day").endOf("day").toDate(),
      ];
    case 9:
      // range from start of last 7 days to end of last 7 days
      return [
        moment().subtract(90, "day").startOf("day").toDate(),
        moment().subtract(1, "day").endOf("day").toDate(),
      ];
    case 10:
      // range from start of previous month to end of previous month
      return [moment().startOf("year").toDate(), moment().subtract(1, "day").endOf("day").toDate()];
    case 12:
      // range from start of previous month to end of previous month
      return [null, null];
    case 13:
      // range from start of previous month to end of previous month
      return [
        moment().subtract(1, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ];
    case 11:
      // range from start of yesterday date in the previous year to end of yesterday date in previous year
      const prevYearYesterday = moment().subtract(1, "year").subtract(1, "day");
      // prevYearYesterday
      return [prevYearYesterday.startOf("day").toDate(), prevYearYesterday.endOf("day").toDate()];
    default:
      return [new Date(), new Date()];
  }
}
