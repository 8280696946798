import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

let called = false;
let session = "basit@capabl.com";

/**
 * Call when loading the app
 */
export function enableLogRocket() {
  if (process.env.REACT_APP_LOGROCKET === undefined || process.env.REACT_APP_LOGROCKET === null) {
    return;
  }

  if (!called) {
    called = true;

    LogRocket.init(process.env.REACT_APP_LOGROCKET, {
      shouldCaptureIP: false,
    });
    setupLogRocketReact(LogRocket);
  }
}

/**
 * Uses the last email used as a session
 */
export function setLogRocketIdentity(email: string, name: string, companyName: string) {
  if (process.env.REACT_APP_LOGROCKET === undefined || process.env.REACT_APP_LOGROCKET === null) {
    return;
  }
  if (email !== session) {
    LogRocket.identify(email, {
      email: email,
      name: name,
      company: companyName,
    });
    session = email;
  }
}

/**
 * captures messages as issues
 */
export function logErrorToLogRocket(message: string, type: "error" | "warn") {
  LogRocket.captureMessage(message, {
    tags: {
      type: type,
    },
  });
}
